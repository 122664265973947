import React, { useState } from "react";
import fetchData from "../../data/fetchData";
import "./index.css";

const Feedback = ({ open }) => {

    const [feed, setFeed] = useState("");

    window.addEventListener("keydown", (e) => {
        // console.log(e);
        if (e.key === "Escape") {
            open(false);
        }
    });

    const send = async () => {
        try {

            if (!feed) {
                alert("please enter something about your feedback...");
                return;
            }

            const res = await fetchData("POST", `${process.env.REACT_APP_SERVER_URL}/feedback`, { feedback: feed });

            if (res && res.status === "ok") {
                alert("Thanks for your feedback...");
                open(false);
            }

        } catch (err) {

        }
    }

    return (
        <>
            <div className="modal-container">

                <div className="modal-viewer">

                    <h1 style={{ color: "green" }}>Feedback</h1>

                    <textarea className="feed-textarea" value={feed} onChange={(e) => setFeed(e.target.value)} placeholder="Write your feedback / Suggest new feature here...">

                    </textarea>

                    <div style={{ display: "flex", justifyContent: "flex-end", padding: "1% 0" }}>
                        <button style={{ marginRight: "3rem" }} className="download-button" onClick={() => { open(false) }} >Cancel</button>
                        <button onClick={send} className="download-button">Send</button>
                    </div>

                </div>

            </div>
        </>
    );
}

export default Feedback;