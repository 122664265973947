import React, { useEffect, useState } from "react";
import fetchData from "../data/fetchData";
import "../styles/intents.css";
import { Link } from "react-router-dom";
import Header from "../components/Header";

const Intents = () => {

    const [intents, setIntents] = useState([]);
    const [midIntents, setMidIntents] = useState([]);
    const [highIntents, setHighIntents] = useState([]);
    const [intentType, setIntentType] = useState(0);

    const getIntents = async () => {
        try {

            const data = await fetchData("GET", `${process.env.REACT_APP_SERVER_URL}/intents`);

            // console.log("Intents data - ", data);

            if (data && localStorage) {
                localStorage.setItem("intents", JSON.stringify(data));

                setIntents(data.intents);
                setHighIntents(data.highLevelIntents);
                setMidIntents(data.midLevelIntents);
            }

        } catch (err) {
            // console.log(err);
        }
    }

    const downloadIntent = async () => {
        try {

            const data = await fetchData("GET", `${process.env.REACT_APP_SERVER_URL}/downloadIntents`, null, "blob");

            const blobURL = window.URL.createObjectURL(new Blob([data]));

            const a = document.createElement("a");
            a.href = blobURL;
            const file_name = "intents.xlsx";
            a.setAttribute("download", file_name);
            a.setAttribute("target", "_blank");
            document.body.appendChild(a);
            a.click();
            a.remove();

        } catch (err) {
            // console.log(err);
        }
    }

    useEffect(() => {
        let localIntents = localStorage.getItem("intents");
        if (localIntents) {
            let data = JSON.parse(localIntents);

            setIntents(data.intents);
            setHighIntents(data.highLevelIntents);
            setMidIntents(data.midLevelIntents);
            getIntents();

        } else {
            getIntents();
        }
    }, [])

    // console.log("intentType = ", intentType);

    return (
        <>
            {/* Header Area Started */}

            <Header
                child={
                    <div className="intent-header">

                        {/* Intent Type Display Area */}
                        <div className="intent-header-select-intent-type">
                            {
                                intentType === 0 ? <h1>Intents</h1> : intentType === 1 ? <h1>Mid Level Intents</h1> : <h1>High Level Intent</h1>
                            }
                        </div>

                        <div>
                            <button onClick={downloadIntent} className="download-button">Download</button>
                        </div>

                        {/* Intent Type selector Area */}
                        <div className="intent-type">
                            <button className="intent" style={{ backgroundImage: intentType === 0 ? "linear-gradient(to bottom right, #50C878, #98FB98, lightgreen)" : "linear-gradient(to bottom right,white, white)" }} onClick={() => setIntentType(0)}>Intents</button>
                            <button className="mid-intent" style={{ backgroundImage: intentType === 1 ? "linear-gradient(to bottom right, #50C878, #98FB98, lightgreen)" : "linear-gradient(to bottom right, white, white)" }} onClick={() => setIntentType(1)}>Mid Level Intents</button>
                            <button className="high-intent" style={{ backgroundImage: intentType === 2 ? "linear-gradient(to bottom right, #50C878, #98FB98, lightgreen)" : "linear-gradient(to bottom right, white, white)" }} onClick={() => setIntentType(2)}>High Level Intents</button>
                        </div>
                    </div>
                }
            />

            {/* Header Area Ended */}

            <div style={{ padding: "1%", overflow: "auto" }}>

                {
                    intentType === 0 ?
                        <>
                            <div style={{ display: "flex", flexDirection: "column" }}>

                                <div style={{ display: "flex", padding: "1%" }}>
                                    <p style={{ width: "10%", fontSize: "1.4rem" }}>UID</p>
                                    <p style={{ width: "50%", fontSize: "1.4rem" }}>Intent Name</p>
                                    <p style={{ width: "10%", fontSize: "1.4rem" }}>Is Covered</p>
                                    <p style={{ width: "10%", fontSize: "1.4rem" }}>Status</p>
                                    <p style={{ width: "20%", textAlign: "center", fontSize: "1.4rem" }}>WID, NodeId</p>
                                </div>
                                <br />

                                {
                                    intents.map((intent) => {
                                        return (
                                            <div style={{ boxShadow: "0.1rem 0.1rem 0.5rem lightgrey", display: "flex", marginBottom: "1rem", padding: "1%", backgroundColor: "white" }}>
                                                <p style={{ width: "10%", fontSize: "1.4rem" }}>{intent.intentUniqueId}</p>
                                                <p style={{ width: "50%", fontSize: "1.4rem" }}>{intent.intent_name}</p>
                                                <p style={{ width: "10%", fontSize: "1.4rem" }}>{intent.isCovered.toString()}</p>
                                                <p style={{ width: "10%", fontSize: "1.4rem" }}>{intent.status.toString()}</p>
                                                <p style={{ width: "20%", textAlign: "center", fontSize: "1.4rem", color: "green", }}><Link style={{ textDecoration: "none", color: "green" }} to={`/BotManager/workflows/${intent.reset_workflow.wid}`}>{intent.reset_workflow.wid}</Link>, {intent.reset_workflow.nodeId} </p>
                                            </div>
                                        );
                                    })
                                }
                            </div>
                        </>
                        :
                        intentType === 1 ?
                            <>
                                <div style={{}}>

                                    <div style={{ display: "flex", padding: "1%", }}>
                                        <p style={{ width: "10%", fontSize: "1.4rem" }}>ID</p>
                                        <p style={{ width: "60%", fontSize: "1.4rem" }}>Mid Level Intent Name</p>
                                        <p style={{ width: "15%", fontSize: "1.4rem" }}>Is Covered</p>
                                        <p style={{ width: "15%", fontSize: "1.4rem" }}>Is Active</p>

                                    </div>
                                    <br />

                                    {
                                        midIntents.map((intent, index) => {
                                            return (
                                                <div style={{ boxShadow: "0.1rem 0.1rem 0.5rem lightgrey", display: "flex", marginBottom: "1rem", backgroundColor: "white", padding: "1%" }}>
                                                    <p style={{ width: "10%", fontSize: "1.4rem" }}>{index + 1}</p>
                                                    <p style={{ width: "60%", fontSize: "1.4rem" }}>{intent.name}</p>
                                                    <p style={{ width: "15%", fontSize: "1.4rem" }}>{intent.isCovered.toString()}</p>
                                                    <p style={{ width: "15%", fontSize: "1.4rem" }}>{intent.isActive.toString()}</p>
                                                </div>
                                            );
                                        })
                                    }
                                </div>

                            </>
                            :
                            <>
                                <div className="high-level-intent-area">
                                    <div style={{ display: "flex", padding: "1%" }}>
                                        <p style={{ width: "10%", fontSize: "1.4rem" }}>ID</p>
                                        <p style={{ width: "25%", fontSize: "1.4rem" }}>High Level Intent Name</p>
                                        <p style={{ width: "10%", fontSize: "1.4rem" }}>Is Covered</p>
                                        <p style={{ width: "10%", fontSize: "1.4rem" }}>Is Active</p>
                                        <p style={{ width: "15%", fontSize: "1.4rem" }}>Start Intent</p>
                                        <p style={{ width: "30%", fontSize: "1.4rem" }}>Mid Intents</p>

                                    </div>
                                    <br />

                                    {
                                        highIntents.map((intent, index) => {
                                            let highs = intent.midLevelIntents.join(", ");
                                            // highs = highs.substring(0, highs.length - 2);
                                            return (
                                                <div style={{ boxShadow: "0.1rem 0.1rem 0.5rem lightgrey", display: "flex", marginBottom: "1rem", backgroundColor: "white", padding: "1%" }}>
                                                    <p style={{ width: "10%", fontSize: "1.4rem" }}>{index + 1}</p>
                                                    <p style={{ width: "25%", fontSize: "1.4rem" }}>{intent.name}</p>
                                                    <p style={{ width: "10%", fontSize: "1.4rem" }}>{intent.isCovered.toString()}</p>
                                                    <p style={{ width: "10%", fontSize: "1.4rem" }}>{intent.isActive.toString()}</p>
                                                    <p style={{ width: "15%", fontSize: "1.4rem" }}>{intent.startIntent}</p>
                                                    <p style={{ width: "30%", fontSize: "1.4rem", color: "green", overflow: "hidden", textOverflow: "ellipsis", lineClamp: 1 }} title={highs}>{highs}</p>
                                                </div>
                                            );
                                        })
                                    }
                                </div>
                            </>

                }
            </div>
        </>
    );
}

export default Intents;