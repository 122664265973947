import React, { useState, useEffect } from "react";
import Header from "../components/Header";
import fetchData from "../data/fetchData";
import "../styles/testing-number.css";

// sub component for each testing number
const EditNumber = ({ number, index, callback }) => {
    const [num, setNum] = useState(number.msisdn);
    const [isEditing, setIsEditing] = useState(false);

    return (
        <div style={{ display: "flex", padding: "1%", alignItems: "center", backgroundColor: "white", marginBottom: "1rem" }}>
            <p style={{ fontSize: "1.4rem", width: "10%" }}>{index + 1}.</p>
            <p style={{ fontSize: "1.4rem", width: "25%", fontWeight: "bold" }}>

                <input type="number" style={{ fontWeight: "bold", outline: "none", padding: "2%", border: isEditing ? "0.1rem solid grey" : "none" }} value={num} onChange={(e) => setNum(+e.target.value)} />

            </p>
            <p style={{ fontSize: "1.6rem", width: "10%", cursor: "pointer", textAlign: "center" }} title={`Copy | ${number.msisdn}`} onClick={(e) => {
                navigator.clipboard.writeText(number.msisdn)

                e.target.innerHTML = "✅";

                setTimeout(() => e.target.innerHTML = "📃", 3000);

            }}>📃</p>
            <p style={{ fontSize: "1.4rem", width: "10%" }}>{new Date(number.createdAt).toLocaleDateString()}</p>
            <p style={{ fontSize: "1.4rem", width: "15%" }}>{new Date(number.updatedAt).toLocaleDateString()}</p>
            <p style={{ fontSize: "1.4rem", width: "15%" }}>{number.toBeDeleted ? number.toBeDeleted.toString() : "-"}</p>

            {
                !isEditing ?
                    <>
                        <p onClick={() => setIsEditing(true)} style={{ fontSize: "1.4rem", width: "10%", textAlign: "center" }}>✒️</p>
                        <p onClick={() => callback({ type: "delete", _id: number._id, num })} style={{ fontSize: "1.4rem", textAlign: "center", width: "10%", cursor: "pointer" }}>🪣</p>
                    </>
                    :
                    <>
                        {
                            num !== number.msisdn
                                ?
                                <>
                                    <button onClick={() => {
                                        if (num.toString().length !== 10) {
                                            alert("Please Enter valid MSISDN.");
                                            return null;
                                        }

                                        setIsEditing(false);
                                        setTimeout(() => callback({ _id: number._id, num }), 50);
                                    }}>Save</button>

                                </>

                                :
                                null
                        }


                        <button onClick={() => {
                            setIsEditing(false);
                        }}>Cancel</button>
                    </>
            }
        </div>
    );
}

const TestingNumber = () => {

    const [numbers, setNumbers] = useState([]);

    const getNumbers = async () => {
        try {

            const data = await fetchData("GET", `${process.env.REACT_APP_SERVER_URL}/testingNumbers`);

            console.log(data.testingNumbers);

            if (data && data.testingNumbers) {
                localStorage.removeItem("numbers");
                localStorage.setItem("numbers", JSON.stringify(data.testingNumbers));
                setNumbers(data.testingNumbers);
            }

        } catch (err) {

        }
    }

    const addNumber = async () => {
        const msisdn = window.prompt("Please Enter an MSISDN !");
        console.log("number going to be added = ", msisdn);

        const data = await fetchData("POST", `${process.env.REACT_APP_SERVER_URL}/testingNumbers`, { msisdn });

        console.log("addition of number res = ", data);

        if (data) {
            getNumbers();
        }
    }

    const editNumber = async ({ _id, num }) => {
        try {

            const data = await fetchData("POST", `${process.env.REACT_APP_SERVER_URL}/updateTestingNumber`, { id: _id, new_msisdn: num.toString() });

            console.log(data);

            if (data && data.message === "ok") {
                getNumbers();
            }

        } catch (err) {
            console.log(err);
        }
    }

    const deleteNumber = async (num) => {
        try {
            const data = await fetchData("GET", `${process.env.REACT_APP_SERVER_URL}/testingNumbers/${num}`);

            console.log("delete number response = ", data);

            if (data && data.message === "ok") {
                getNumbers();
            }
        } catch (err) {
            console.log(err);
        }
    }

    useEffect(() => {
        let localnums = localStorage.getItem("numbers");
        if (localnums) {
            const nums = JSON.parse(localnums);
            setNumbers(nums);
            getNumbers();

        } else {
            getNumbers();
        }
    }, [])

    return (
        <>
            {/* Header Area Started */}
            <Header
                child={
                    <div style={{ zIndex: 9999, padding: "0% 2%", position: "sticky", top: 0, display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                        <h1>Testing Number</h1>
                        <h1 onClick={addNumber} style={{ fontSize: "1.5rem", padding: "0.5% 2%", display: "grid", placeItems: "center", borderRadius: "3rem", cursor: "pointer", border: "0.1rem solid grey" }}>+ Add MSISDN</h1>
                    </div>
                }
            />
            {/* Header Area Ended */}

            <div style={{ padding: "2%" }}>
                <div style={{ display: "flex", padding: "1%", alignItems: "center", marginBottom: "1rem" }}>
                    <p style={{ fontSize: "1.4rem", width: "10%" }}>Sr. No.</p>
                    <p style={{ fontSize: "1.4rem", width: "25%" }}> MSISDN </p>
                    <p style={{ fontSize: "1.4rem", width: "10%", textAlign: "center" }} >Copy</p>
                    <p style={{ fontSize: "1.4rem", width: "10%" }}>Created At</p>
                    <p style={{ fontSize: "1.4rem", width: "15%" }}>Updated At</p>
                    <p style={{ fontSize: "1.4rem", width: "15%" }}>To Be Deleted</p>
                    <p style={{ fontSize: "1.4rem", width: "20%", textAlign: "center" }}>Actions</p>
                </div>
                {
                    numbers.map((number, index) => {
                        return (
                            <EditNumber
                                number={number}
                                index={index}
                                callback={({ type = null, _id, num }) => {

                                    if (type && type === "delete") {
                                        let res = window.confirm("Are you sure ?");

                                        if (!res) return;

                                        deleteNumber(num);
                                        return null;
                                    }

                                    editNumber({ _id, num });
                                }}
                            />
                        );
                    })
                }
            </div>

        </>
    );
}

export default TestingNumber;