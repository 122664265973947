
/**
 * 
 * @param {String} method 
 * @param {String} url 
 * @param {Object} data 
 * @param {String} dataType 
 * @returns api response
 */
const fetchData = async (method, url, data, dataType) => {
    try {

        const ud = localStorage.getItem("loginDetails");

        let token, options = {};

        if (ud) {
            token = JSON.parse(ud).token;
        }

        if (data && data.type === "upload_file") {


            let headers = new Headers();
            headers.append("Authorization", "Bearer " + token);

            options = {
                method,
                body: data.body,
                headers
            }

        } else {
            options = {
                method,
                ...(data && { body: JSON.stringify(data), headers: { "Content-Type": "application/json" } })
            }

            if (token) {
                options.headers = {
                    "Content-Type": "application/json",
                    Authorization: "Bearer " + token
                }
            }
        }


        // console.log("url - ", url);
        // console.log("options - ", options);

        const raw_res = await fetch(url, options);

        let res;

        if (dataType === "blob") {
            res = await raw_res.blob();
        } else {
            res = await raw_res.json();
        }

        return res;

    } catch (err) {
        // console.log(err);
        return null;
    }
}

export default fetchData;