import React from "react";

const UtteranceCard = (props) => {

    // console.log(props.isModified ? "red" : null);

    return (
        <>
            <div onClick={props.callback} style={{ cursor: "pointer", transition: "all 0.2s linear", backgroundColor: props.isSelectedToBeHighlighted ? "aliceblue" : "white", borderRadius: "1rem", border: "0.1rem solid " + (props.isModified ? "red" : "lightgrey"), padding: "1rem", marginBottom: "1.5rem", boxShadow: "0.1rem 0.1rem 1rem lightgrey" }}>

                <p style={{ fontSize: "1.3rem" }}>
                    {props.utterance}
                </p>

                <br />

                <div style={{ display: "flex", overflowX: "auto", padding: "1rem 0" }}>
                    {
                        Array.isArray(props.intents) && props.intents?.length > 0
                            ?
                            props.intents?.map((intent) => {
                                return (
                                    <>
                                        <div style={{ marginRight: "1rem", padding: "0.6rem 1.5rem", border: "0.1rem solid grey", borderRadius: "1rem" }}>
                                            <h2 style={{ color: "green" }}>{intent}</h2>
                                        </div>
                                    </>
                                );
                            })
                            :
                            <div> </div>
                    }
                </div>
            </div>
        </>
    );
}

export default UtteranceCard;