import React, { useEffect, useState } from "react";
import Header from "../components/Header";
import fetchData from "../data/fetchData";

import ORILOGO from "../assets/images/ori-logo-solo.png";

import "../styles/index.css"
import "../styles/bot-report.css";


const BotReport = () => {

    const [showSpinner, setShowSpinner] = useState(false);

    const [url, setUrl] = useState("https://vil-email.oriserve.com");
    const [selectedEmails, setSelectedEmails] = useState([]);
    const [channelName, setChannelName] = useState("");
    const [startDateTime, setStartDateTime] = useState();
    const [endDateTime, setEndDateTime] = useState();
    const [responseMode, setResponseMode] = useState("");
    const [mail, setMail] = useState("");

    // all emaployee ids
    const [empIds, setEmpIds] = useState([]);

    const [response, setResponse] = useState("");

    const addEmails = (mail) => {
        setSelectedEmails((prevData) => {
            if (!prevData.includes(mail)) {
                return [...prevData, mail];
            } else {
                return prevData;
            }
        })
    }

    const removeEmail = (index) => {
        let dups = [...selectedEmails];
        dups.splice(index, 1);
        setSelectedEmails(dups);
    }

    const getData = async () => {
        try {
            setShowSpinner(true);

            let a = "";

            const mails = [...selectedEmails];
            let channel = channelName;
            let startDate = startDateTime;
            let endDate = endDateTime;
            let response = responseMode;

            if (mails && mails.length > 0) {
                // console.log(mails);

                for (let i = 0; i < mails.length; ++i) {
                    a += `receiverId[${i}]=${mails[i]}&`;
                }

            } else {
                alert("please add mails...");
                setShowSpinner(false);
                return null;
            }

            if (channel) a += "channelName=" + channel + "&";
            if (startDate) a += "startDateTime=" + startDate + "&";
            if (endDate) a += "endDateTime=" + endDate + "&";
            if (response) a += "responseMode=" + response;
            if (a.endsWith("&")) a = a.substring(0, a.length - 1);

            a = url + "/admin/filterBotResults/v2?" + a;

            let res = await fetchData("GET", a);

            if (res) {
                setResponse(JSON.stringify(res));
                setShowSpinner(false);
            } else {
                setResponse("Something went wrong...");
                setShowSpinner(false);
            }



        } catch (err) {
            // console.log(err);
            setShowSpinner(false);
            setResponse(err.message.toString());
        }
    }

    const getEmpIds = async () => {
        try {
            const empnames = await fetchData("GET", `${process.env.REACT_APP_SERVER_URL}/getAuthEmails`);

            if (empnames) {
                setEmpIds(empnames.authMails);
                localStorage.setItem("empnames", JSON.stringify(empnames.authMails));
            }

        } catch (err) {
            // console.log(err);
        }
    }

    useEffect(() => {

        document.title = "Email bot History"

        let empnames = localStorage.getItem("empnames");

        if (empnames) {
            empnames = JSON.parse(empnames);
            setEmpIds(empnames);
            getEmpIds()

        } else {
            getEmpIds()
        }

        return () => {
            document.title = "Email Bot Dashboard";
        }

    }, []);

    return (
        <>

            {
                showSpinner ?
                    <>
                        <div style={{ zIndex: 99999, position: "fixed", display: "grid", placeItems: "center", top: 0, left: 0, bottom: 0, width: "100%", height: "100%", background: "rgba(255, 255, 255, 0.6)", backdropFilter: "blur(10px)" }}>
                            <img alt="spinner" className="spinner-image" style={{ width: "20%", height: "auto" }} src={ORILOGO} />
                            <h1>Loading...</h1>
                            <progress></progress>
                        </div>
                    </>
                    :
                    null
            }

            {/* Header Area Started */}
            <Header
                child={
                    <div style={{ display: "flex", justifyContent: "space-between", padding: "0% 2%" }}>
                        <h1>Email History</h1>
                        <div>
                            <p style={{ fontSize: "1.4rem" }}>Environment</p>
                            <select defaultValue={url} onChange={(e) => setUrl(e.target.value)}>
                                <option value="http://localhost:8000">Localhost</option>
                                <option value="https://vil-email-sprint.oriserve.com">Sprint Server</option>
                                <option value="https://vil-email-dev.oriserve.com">Dev Server</option>
                                <option value="https://vil-email.oriserve.com">Production Server</option>
                            </select>
                        </div>
                    </div>
                }
            />

            <br />

            <div style={{ display: "flex", flexDirection: "column", backgroundColor: "#e6e6e6", padding: "2%" }}>

                <div style={{ display: "flex", justifyContent: "space-between", alignItems: "flex-end" }}>

                    {/* Enter Email */}
                    <div style={{ width: "64%" }}>

                        <p style={{ marginBottom: "0.5rem", fontSize: "1.5rem", display: "flex", flexWrap: "wrap", alignItems: "center" }}>Select Email :
                            {
                                selectedEmails?.map((email, index) => {
                                    return (
                                        <span style={{ fontSize: "1.2rem", margin: "0.2rem 0.5rem", padding: "0.5rem", border: "0.1rem solid grey" }}>
                                            {email.split("@")[0].split(".").join(" ")}
                                            <span style={{ cursor: "pointer", margin: "0 0.5rem" }} onClick={() => removeEmail(index)}>
                                                ❌
                                            </span>
                                        </span>
                                    );
                                })
                            }
                        </p>

                        <datalist id="userEmails">
                            {
                                empIds?.map((email) => {
                                    return (
                                        <option value={email} label={email} />
                                    );
                                })
                            }
                        </datalist>

                        <input list="userEmails"
                            value={mail}
                            placeholder="User Email. Ex: abc.xyz@oriserve.com"
                            onInput={(e) => {
                                setMail(e.target.value)
                                if (e.target.value.endsWith("@oriserve.com")) {
                                    addEmails(e.target.value);
                                    setMail("");
                                }
                            }}
                        />
                    </div>

                    {/* channel */}
                    <div style={{ width: "32%" }}>
                        <p style={{ fontSize: "1.5rem", marginBottom: "0.5rem" }}>Channel Name</p>
                        <input type="text" placeholder="channel name" id="channelName" value={channelName} onChange={(e) => setChannelName(e.target.value)} />

                    </div>
                </div>

                <br /><br />

                <div style={{ display: "flex", justifyContent: "space-between" }}>

                    <div style={{ width: "32%" }}>
                        <p style={{ fontSize: "1.5rem", marginBottom: "0.5rem" }}>Start Date</p>
                        <input type="date" id="startDateTime" value={startDateTime} onChange={(e) => setStartDateTime(e.target.value)} />

                    </div>

                    <div style={{ width: "32%" }}>
                        <p style={{ fontSize: "1.5rem", marginBottom: "0.5rem" }}>End Date</p>
                        <input type="date" id="endDateTime" value={endDateTime} onChange={(e) => setEndDateTime(e.target.value)} />

                    </div>

                    <div style={{ width: "32%" }}>
                        <p style={{ fontSize: "1.5rem", marginBottom: "0.5rem" }}>Response Mode</p>
                        <input type="text" id="responseMode" placeholder="AUTO, ASSISTED, UNHANDLED" value={responseMode} onChange={(e) => setResponseMode(e.target.value)} />

                    </div>

                </div>

                <br /><br />

                <div style={{ display: "flex", justifyContent: "flex-end" }}>
                    <button onClick={getData} className="fetch-button">Fetch Bot History</button>
                </div>

            </div>

            <br />
            <br />

            {/* Result */}
            <pre style={{ width: "100%", padding: "2%", marginTop: "2rem", backgroundColor: "#e6e6e6", overflow: "auto" }}>
                <h1>{JSON.stringify(response)}</h1>
            </pre>
        </>
    );
}

export default BotReport;