import React from "react";
import ReactDOM from "react-dom/client";
import { BrowserRouter } from "react-router-dom"
import App from "./App";

const root = ReactDOM.createRoot(document.getElementById("root"));

if (process.env.NODE_ENV === "production") {
    console.log = () => { };
}

root.render(
    <>
        <BrowserRouter>
            <App />
        </BrowserRouter>
    </>
)