import React, { useEffect, useState } from "react";
import fetchData from "../../data/fetchData";
import "./css/index.css";
import SearchComponent from "./comps/SearchComponent";
import UtteranceCard from "./comps/UtteranceCard";

const MidIntents = () => {

    const [currUttrDetails, setCurrUttrDetails] = useState({});
    const [totalMidUtterances, setTotalMidUtterances] = useState(0);
    const [midIntentUtterances, setMidIntentUtterances] = useState([]);
    const [dupMidIntentUtterances, setDupMidIntentUtterances] = useState([]);

    // console.log("midIntentUtterances.length - ", midIntentUtterances.length);
    // console.log("dupMidIntentUtterances.length - ", dupMidIntentUtterances.length);

    const [midIntents, setMidIntents] = useState([]);
    const [dupMidIntents, setDupMidIntents] = useState([]);
    const [dup2MidIntents, setDup2MidIntents] = useState([]);
    const [midIntentSearchText, setMidIntentSearchText] = useState("");

    const [showIntentSelector, setShowIntentSelector] = useState(false);

    const [selectedMidIntentForTraining, setSelectedMidIntentForTraining] = useState([]);

    const [utterance, setUtterance] = useState();

    const [skip, setSkip] = useState(0);

    const getMidIntentUtterances = async () => {
        try {
            let data = await fetchData("GET", `${process.env.REACT_APP_SERVER_URL}/getMidIntentUtterances?skip=${skip}`);

            // console.log("getMidIntentUtterances = ", data);

            data.midIntens = data.midIntens.filter((d) => d._id ? true : false);

            setMidIntents(data.midIntens);
            setDupMidIntents(data.midIntens);
            setDup2MidIntents(data.midIntens);

            setMidIntentUtterances(data.midlevelutterance);
            setDupMidIntentUtterances(data.midlevelutterance);

            setTotalMidUtterances(data.totalUttrances);

        } catch (err) {
            console.log(err);
        }
    }

    const manageSelectedMidIntent = (val) => {

        // console.log(dupMidIntents);

        let newData1 = midIntents.map((i) => {
            if (i?.mid_intents === val && !i?.checked) return { ...i, checked: true };
            else return { ...i, checked: false };
        });

        let newData2 = dupMidIntents.map((i) => {
            if (i?.mid_intents === val && !i?.checked) return { ...i, checked: true };
            else return { ...i, checked: false };
        });

        // console.log(newData1, newData2);

        setMidIntents(newData1);
        setDupMidIntents(newData2);
    }

    const saveUtteranceForTraining = () => {
        // console.log("Utterance => ", utterance);
        // console.log("selectedMidIntentForTraining => ", selectedMidIntentForTraining[0]);

        if (!utterance.trim()) {
            alert("Kindly add the utterance to be train with.");
            return null;
        }

        if (!selectedMidIntentForTraining[0]?.trim()) {
            alert("Kindly select the intent for training");
            return null;
        }

        for (const uttr1 of midIntentUtterances) {
            if (uttr1 && uttr1.utterance === utterance) {
                alert("This utterance is already there in the mid intent training dataset.");
                return null;
            }
        }

        let uttToBeTrained = {
            _id: "new_uttr_" + new Date().getTime(),
            utterance,
            mid_intent: selectedMidIntentForTraining[0]
        }

        setUtterance("");
        setSelectedMidIntentForTraining([]);

        setTotalMidUtterances(+totalMidUtterances + 1);
        setDupMidIntentUtterances([uttToBeTrained, ...midIntentUtterances]);
        setMidIntentUtterances([uttToBeTrained, ...midIntentUtterances]);
    }

    const deleteUtteranceForTraining = () => {

        setMidIntentUtterances(midIntentUtterances.filter((u) => {
            if (!u._id) return true;
            else if (u._id !== currUttrDetails._id) return true;
            else return false;
        }));

        setUtterance("");
        setSelectedMidIntentForTraining([]);
        setCurrUttrDetails({});

        setMidIntents(dup2MidIntents);
        setDupMidIntents(dup2MidIntents);
        setTotalMidUtterances(+totalMidUtterances - 1);
    }

    const loadUttrDetails = (uttr) => {
        // console.log("UTTR -> ", uttr);

        let new_mid_intents = midIntents.map((i) => {
            if (i.mid_intents === uttr.mid_intent) {
                return {
                    ...i,
                    checked: true
                }
            } else {
                return i;
            }
        });

        setMidIntents(new_mid_intents);
        setDupMidIntents(new_mid_intents);
        setSelectedMidIntentForTraining([uttr.mid_intent]);
        setCurrUttrDetails(uttr);
        setUtterance(uttr.utterance);
    }

    useEffect(() => {
        // console.log(setSkip);
        getMidIntentUtterances(); // eslint-disable-next-line
    }, []); // eslint-disable-next-line

    return (
        <div className="mid-level-container">

            {/* Mid level container child 1 just to show header */}
            <div>
                <h2>Mid Level Intent Training</h2>
                <button>Save Data</button>
            </div>

            {/* Mid level container child 2 having all the things */}
            <div style={{ display: "flex", flex: 1, justifyContent: "space-between" }}>

                {/* Box 1 to show the utterances */}
                <div style={{ display: "flex", overflowY: "scroll", flexDirection: "column", width: "48%", backgroundColor: "white", border: "0.1rem solid lightgrey" }}>

                    {/* Search BOX */}
                    <div style={{ position: "sticky", padding: "2rem", top: 0, backgroundColor: "white", borderBottom: "0.2rem solid lightgrey" }}>

                        <SearchComponent
                            utterances={dupMidIntentUtterances}
                            highIntents={dup2MidIntents}
                            type="mid_intent"
                            callback={(inputText, filteredUttr) => {
                                // console.log("inputText = ", inputText);
                                setMidIntentUtterances(filteredUttr);
                            }}
                        />

                        <p style={{ margin: "0.5rem 0", textAlign: "right" }}>{`${midIntentUtterances.length} / ${totalMidUtterances}`} Results Loaded...</p>
                    </div>

                    {/* Search result data with all utterances */}
                    <div style={{ height: "10rem", padding: "1rem" }}>
                        {
                            midIntentUtterances?.map((uttr) => {
                                return (
                                    <UtteranceCard
                                        _id={uttr._id}
                                        utterance={uttr.utterance}
                                        isSelectedToBeHighlighted={currUttrDetails._id === uttr._id ? true : false}
                                        intents={[uttr.mid_intent]}
                                        callback={(data) => {
                                            // console.log(data);
                                            loadUttrDetails(uttr)
                                        }}
                                    />
                                );
                            })
                        }
                    </div>
                </div>

                {/* Box 2 to show details with basic crud operations capability */}
                <div style={{ position: "relative", display: "flex", flexDirection: "column", width: "52%", backgroundColor: "white", border: "0.1rem solid lightgrey" }}>

                    {/* Intent Selector modal -> very handy to select multiple intents at a time */}
                    {
                        showIntentSelector ?
                            <div style={{ border: "0.1rem solid grey", overflow: "auto", borderRadius: "1rem 0 0 1rem", position: "absolute", width: "60%", height: "100%", backgroundColor: "white", right: 0 }}>

                                <div style={{ padding: "1rem", backgroundColor: "white", textAlign: "right", fontSize: "2.5rem", borderBottom: "0.1rem solid lightgrey", cursor: "pointer", position: "sticky", top: 0 }}>
                                    <input
                                        placeholder="Enter text to search"
                                        type="text"
                                        value={midIntentSearchText}
                                        onChange={(e) => {
                                            setMidIntentSearchText(e.target.value);

                                            // console.log(e.target.value);

                                            if (e.target.value === "") {
                                                setMidIntents(dupMidIntents);
                                            }
                                            else {
                                                setMidIntents(dupMidIntents.filter((intent) => {
                                                    return intent?.mid_intents?.includes(e.target.value);
                                                }))
                                            }

                                        }} />
                                </div>

                                <div>
                                    {
                                        midIntents?.map((intent) => {
                                            return (
                                                <>
                                                    <label style={{ cursor: "pointer", borderBottom: "0.1rem solid lightgrey", display: "flex", width: "100%", padding: "1rem" }}>

                                                        <input onChange={(e) => {
                                                            // console.log(e.target.value);
                                                            manageSelectedMidIntent(intent.mid_intents);
                                                        }}
                                                            checked={intent?.checked ?? false}
                                                            style={{ fontSize: "2rem", width: "20%" }}
                                                            type="radio"
                                                            name="midIntent"
                                                        />

                                                        <span>{intent?.mid_intents ?? ""}</span>

                                                    </label>
                                                </>
                                            );
                                        })
                                    }
                                </div>

                                <div style={{ display: "flex", justifyContent: "space-between", fontSize: "1.8rem", fontWeight: "bold", padding: "1.5rem", border: "0.1rem solid lightgrey", backgroundColor: "white", width: "100%", position: "sticky", bottom: 0 }}>

                                    <button
                                        onClick={() => {
                                            setSelectedMidIntentForTraining([]);
                                            let newMidIntens = midIntents.map((i) => ({ ...i, checked: false }));
                                            setMidIntents(newMidIntens);
                                            setDupMidIntents(newMidIntens);
                                            setShowIntentSelector(false);
                                        }}
                                        style={{ cursor: "pointer", border: "0.1rem solid grey", fontSize: "1.8rem", width: "45%" }}>
                                        Cancel
                                    </button>

                                    <button
                                        onClick={() => {
                                            setSelectedMidIntentForTraining(midIntents.map((i) => i.checked ? i.mid_intents : "").filter((val) => val ? val : false))
                                            setShowIntentSelector(false);
                                        }}
                                        style={{ cursor: "pointer", border: "0.1rem solid grey", fontSize: "1.8rem", width: "45%" }}>
                                        Done
                                    </button>
                                </div>
                            </div>
                            :
                            <></>
                    }

                    {/* Showing selected intents upon which utterance will be trained */}
                    <div style={{ height: "30%", padding: "1rem" }}>

                        <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                            <p style={{ fontSize: "1.5rem" }}>Intents To Be Trained with</p>
                            <h1 onClick={() => setShowIntentSelector(true)} style={{ cursor: "pointer", width: "3rem", height: "3rem", border: "0.2rem solid black", borderRadius: "5rem", display: "grid", placeItems: "center" }}>✚</h1>
                        </div>

                        <hr style={{ margin: "1rem 0" }} />
                        {
                            selectedMidIntentForTraining.length > 0 ?
                                // Intents To Be Trained with will be populate over here
                                selectedMidIntentForTraining?.map((i) => {
                                    return <p>{i}</p>
                                })
                                :
                                <h1 onClick={() => setShowIntentSelector(true)} style={{ flex: 1, height: "70%", display: "grid", placeItems: "center", cursor: "pointer", color: "lightgrey" }}>Add +</h1>
                        }
                    </div>

                    {/* Basic Crud BUttons and textarea to add the mail utterance */}
                    <div style={{ flex: 1, padding: "1rem", height: "70%", display: "flex", flexDirection: "column", alignItems: "flex-end" }}>

                        <p style={{ fontSize: "1.6rem", marginBottom: "1rem", textAlign: "left", width: "100%" }}>Add Utterance</p>

                        <textarea
                            value={utterance}
                            onChange={(e) => setUtterance(e.target.value)}
                            placeholder="add utterance here" style={{ resize: "none", width: "100%", height: "70%", padding: "1rem" }}
                        >
                        </textarea><br />

                        <div style={{ width: "100%", display: "flex", justifyContent: "space-between" }}>

                            {
                                currUttrDetails._id && currUttrDetails._id.includes("new_uttr_") ?
                                    <button onClick={deleteUtteranceForTraining} className="download-button">Delete</button>
                                    :
                                    <button></button>
                            }

                            <button onClick={saveUtteranceForTraining} className="download-button">Add</button>
                        </div>

                    </div>

                </div>

            </div>
        </div >
    );
}

export { MidIntents as default };