import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import Header from "../components/Header";
import fetchData from "../data/fetchData";
import "../styles/workflows.css";

const Workflows = () => {

    const [workflows, setWorkflows] = useState([]);
    const [dupWorkflows, setDupWorkflows] = useState([]);

    const [showPermissions, setShowPermissions] = useState(false);
    const [currWorkflow, setCurrWorkflow] = useState({});

    const [searchParam, setSearchParam] = useState("Name");

    const getWorkflows = async () => {
        try {
            const data = await fetchData("GET", `${process.env.REACT_APP_SERVER_URL}/getWorkflows`);

            if (localStorage && data) {
                localStorage.setItem("workflows", JSON.stringify(data));

                setWorkflows(data);
                setDupWorkflows(data);
            }

        } catch (err) {
            console.log(err);
        }
    }

    window.addEventListener("keydown", (e) => {
        if (e.key === "Escape") {
            setShowPermissions(false);
        }
    })

    useEffect(() => {
        const wfs = localStorage.getItem("workflows");

        if (wfs) {
            setWorkflows(JSON.parse(wfs));
            setDupWorkflows(JSON.parse(wfs));
            getWorkflows();

        } else {
            getWorkflows();
        }

    }, [])

    return (
        <>

            {/* Header Area Started */}
            <Header
                child={
                    <div>
                        <h1>Workflows</h1>
                    </div>
                }
            />
            {/* Header Area Ended */}


            {/* Modal Area for Permissions */}
            {
                showPermissions ?
                    <>
                        <div style={{ display: "grid", placeItems: "center", position: "fixed", top: 0, left: 0, width: "100%", height: "100%", background: "rgba(255, 255, 255, 0.4)", backdropFilter: "blur(10px)", zIndex: 9999 }}>

                            <h1 onClick={() => setShowPermissions(false)} style={{ position: "absolute", right: "3%", top: "2%", cursor: "pointer", fontSize: "3.5rem" }}> &times;</h1>

                            <div style={{ padding: "1.5% 3%", width: "80%", height: "80%", overflow: "auto", backgroundColor: "cyan", boxShadow: "0.5rem 1rem 1rem lightgrey", borderRadius: "1.5rem" }}>

                                <pre style={{ fontSize: "1.4rem" }}>
                                    {JSON.stringify(currWorkflow.permissions, null, 4)}

                                </pre>
                            </div>
                        </div>
                    </>
                    :
                    null
            }

            <div className="workflow-container">

                <div className="flex-as-column">

                    {/* Header for List of workflows */}
                    <div className="workflow-row-header">
                        <p>Wid</p>

                        <p>
                            <input
                                value={searchParam}
                                onFocus={() => setSearchParam("")}
                                onBlur={() => setSearchParam("Name")}
                                onChange={(e) => {
                                    setSearchParam(e.target.value);

                                    let filterResult = dupWorkflows.filter((workflow) => workflow.name.toLowerCase().includes(searchParam.toLowerCase()));

                                    setWorkflows(filterResult);

                                    if (e.target.value.length === 0) {
                                        setWorkflows(dupWorkflows);
                                    }
                                }}
                                type="text"
                                placeholder="Name"
                            />
                        </p>

                        <p>Desription</p>
                        <p>Action</p>
                    </div>

                    {
                        workflows.map((workflow) => {
                            return (
                                <div className="workflow-row">

                                    <p>{workflow.wid}</p>

                                    <p><Link className="rr-link" to={`${workflow.wid}`}>{workflow.name}</Link></p>

                                    <p title={workflow.description ? workflow.description : "NA"}>{workflow.description ? workflow.description : "NA"}</p>

                                    <p onClick={() => {
                                        setCurrWorkflow(workflow);
                                        setShowPermissions(true);
                                    }}
                                    >
                                        🔐
                                    </p>

                                    <p>🪣</p>
                                </div>
                            );
                        })
                    }
                </div>
            </div>
        </>
    );
}

export default Workflows;