import React, { useEffect, useState } from "react";

import Header from "../components/Header";
import logospinner from "../assets/images/ori-logo-solo.png";

import fetchData from "../data/fetchData";

import "../styles/api-logs.css";

const APILogs = () => {

    const [APILogs, setAPILogs] = useState([]);

    let today = new Date();

    let month = (today.getMonth() + 1) + "";
    let day1 = (today.getDate() - 1) + "";
    let day2 = (today.getDate()) + "";

    let date1 = today.getFullYear() + "-" + month.padStart(2, 0) + "-" + day1.padStart(2, 0);
    let date2 = today.getFullYear() + "-" + month.padStart(2, 0) + "-" + day2.padStart(2, 0);

    const [startDate, setStartDate] = useState(date1);
    const [endDate, setEndDate] = useState(date2);
    const [selectAPIType, setSelectAPIType] = useState("Failed");

    const [preparedFilter, setPreparedFilter] = useState({});

    const [showSpinner, setShowSpinner] = useState(false);

    const getAPILogs = async () => {
        try {
            setShowSpinner(true);

            // console.log(startDate, endDate, selectAPIType);

            if (!startDate || !endDate || !selectAPIType) {
                alert("Please fill all the required value.");
                setShowSpinner(false);
                return null;
            }

            let filter = {
                startDate: startDate,
                endDate: endDate,
                type: {}
            }

            switch (selectAPIType) {
                case "Success":
                    filter.type.isSuccess = true;
                    break;

                case "Failed":
                    filter.type.isSuccess = false;
                    break;

                case "Timeout":
                    filter.type.isTimeOut = true;
                    break;

                default:
                    // console.log("Something ewnt wrong another than success failed and timeout while checking api logs.");
            }

            // console.log("filter - ", filter);

            setPreparedFilter(filter);

            let res = await fetchData("POST", `${process.env.REACT_APP_SERVER_URL}/getAPIStatus`, filter);

            // console.log("getAPIStatus = ", res);

            if (res && res.status === "ok") {
                setAPILogs(res.data);
                setShowSpinner(false);
            } else {
                alert("Something went wrong...");
                setShowSpinner(false);
            }

        } catch (err) {
            // console.log(err);
            setShowSpinner(false);
        }
    }

    useEffect(() => {
        getAPILogs(); // eslint-disable-next-line
    }, []);

    return (
        <>
            <Header
                child={
                    <div style={{ padding: "0.5rem" }}>
                        <h1>API Logs</h1>
                    </div>
                }
            />

            {
                showSpinner ?
                    <div style={{ zIndex: 9999, position: "fixed", top: 0, left: 0, width: "100%", height: "100%", background: "rgba(255, 255, 255, 0.6)", backdropFilter: "blur(10px)", display: "grid", placeItems: "center" }}>
                        <img alt="spinner-img" className="spinner-image" src={logospinner} style={{ width: "10%", height: "auto" }} />
                        <h1>Loading...</h1>
                        <progress></progress>
                    </div>
                    :
                    null
            }

            {/* Form */}
            <div style={{ padding: "1% 2%" }}>

                {/* Form */}
                <div style={{ display: "flex", justifyContent: "space-between", alignItems: "flex-end" }}>

                    {/* Start Date */}
                    <div style={{ width: "25%" }} >
                        <p style={{ fontSize: "1.6rem", marginBottom: "1rem" }}>Start Date</p>
                        <input placeholder="start date" type="date" id="startDate" value={startDate} onChange={(e) => setStartDate(e.target.value)} />
                    </div>

                    {/* End Date */}
                    <div style={{ width: "25%" }} >
                        <p style={{ fontSize: "1.6rem", marginBottom: "1rem" }}>End Date</p>
                        <input placeholder="end date" type="date" id="endDate" value={endDate} onChange={(e) => setEndDate(e.target.value)} />

                    </div>

                    {/* API type */}
                    <div style={{ width: "25%" }} >
                        <p style={{ fontSize: "1.6rem", marginBottom: "1rem" }}>API Type</p>

                        <select style={{ width: "100%", padding: "0.5rem" }} value={selectAPIType} onChange={(e) => {
                            // console.log(e.target.value);

                            setSelectAPIType(e.target.value)

                        }}>
                            <option value={"Success"} label="Success" />
                            <option value={"Failed"} label="Failed" />
                            <option value={"Timeout"} label="Timeout" />
                        </select>
                    </div>

                    <div style={{ width: "12%" }} >
                        <button style={{ cursor: "pointer", fontSize: "3rem", background: "none", width: "100%", border: "none" }} onClick={getAPILogs}>🔍</button>
                    </div>

                </div>
                <br />
                <hr />
            </div>

            {/* Main Viewer */}
            <div className="container" style={{ padding: "0% 2%" }}>

                <div className="api-log-viewer-header">
                    <p style={{ width: "40%" }}>API Tag Name</p>
                    <p style={{ width: "10%" }}>Count</p>
                    <p className="remove-scrollbar" style={{ width: "50%", overflowX: "scroll" }}>Session Ids</p>
                </div>
                {
                    APILogs?.length > 0 ?
                        <div>
                            {
                                APILogs?.map((api) => {
                                    return (
                                        <div className="api-log-viewer">
                                            <p style={{ width: "40%" }}>{api._id}</p>
                                            <p style={{ width: "10%" }}>{api.count}</p>
                                            <p className="remove-scrollbar" style={{ width: "50%", overflowX: "scroll" }}>
                                                {JSON.stringify(Array.from(new Set(api[Object.keys(api).slice(-1).toString()])))}
                                            </p>
                                        </div>
                                    );
                                })
                            }
                        </div>
                        :
                        <div style={{ padding: "0% 2%" }}>
                            <h1>
                                No Records Available for these filters.
                            </h1>
                            <pre style={{ fontSize: "1.5rem", margin: "2rem 0" }}>
                                {JSON.stringify(preparedFilter, null, 4)}
                            </pre>
                        </div>
                }
            </div>
        </>
    );
}

export default APILogs;