import React, { useEffect, useState } from "react";
import "../../styles/unhandled-analytics.css";
import fetchData from "../../data/fetchData";

const UnhandledAnalytics = () => {

    const [max, setMax] = useState(0);
    const [startDate, setStartDate] = useState(new Date().getFullYear() + "-" + (new Date().getMonth() + 1).toString().padStart(2, "0") + "-" + new Date().getDate().toString().padStart(2, "0") + "T00:00");
    const [endDate, setEndDate] = useState(new Date().getFullYear() + "-" + (new Date().getMonth() + 1).toString().padStart(2, "0") + "-" + new Date().getDate().toString().padStart(2, "0") + "T" + new Date().getHours().toString().padStart(2, "0") + ":" + new Date().getMinutes().toString().padStart(2, "0"));

    const [data, setData] = useState([]);

    console.log("max: ", max);

    const getUnhandledCounts = async () => {
        try {

            const res = await fetchData("GET", `${process.env.REACT_APP_SERVER_URL}/unhandledAnalytics?startDateTime=${startDate}&endDateTime=${endDate}`);

            console.log("Response for unhandled count : ", res);

            if (!res) {
                return alert("Something went while getting unhandled count records...");
            }

            if (res && Array.isArray(res) && res.length > 0) {
                setMax(res[0]?.count ?? 0);
                setData(res);
            }

        } catch (err) {
            console.log("Error while getUnhandled count: ", err);
        }
    }

    useEffect(() => {
        getUnhandledCounts();
        // eslint-disable-next-line
    }, [startDate, endDate]);

    return (
        <div style={{ padding: "0% 2%" }}>

            <div class="unhandled-analytics-container">

                <h1>Unhandled Count</h1>

                <div class="unhandled-analytics-container">
                    <input style={{ width: "45%" }} type="datetime-local" value={startDate} onChange={(e) => setStartDate(e.target.value)} />
                    <h1>~</h1>
                    <input style={{ width: "45%" }} type="datetime-local" value={endDate} onChange={(e) => setEndDate(e.target.value)} />
                </div>
            </div>

            <hr />

            <div>

                <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                    <h2 style={{ width: "20%", padding: "1rem" }}>Unhandle Reason</h2>
                    <div style={{ width: "5%", padding: "1rem" }}>
                        Count
                    </div>
                    <div style={{ width: "69%", display: "flex", padding: "1rem" }}>
                    </div>
                </div>

                <hr />

                {
                    data?.length > 0 ?
                        data?.map((intent) => {

                            let f = Math.floor, r = Math.random, maxAllowed = 255;
                            const bg = "rgba(" + f(r() * maxAllowed) + ", " + f(r() * maxAllowed) + ", " + f(r() * maxAllowed) + ", 1)";
                            const width = Math.ceil((intent.count / (max + 50)) * 100) + "%";

                            return (
                                <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                                    <p style={{ width: "20%", padding: "1rem", fontSize: "1.2rem" }}>{intent._id}</p>
                                    <div style={{ width: "5%", borderLeft: "0.1rem solid lightgrey", padding: "1rem" }}>
                                        {intent.count}
                                    </div>
                                    <div style={{ width: "69%", display: "flex", padding: "1rem" }}>
                                        <div style={{ height: "1.6rem", backgroundColor: bg, width, boxShadow: "inset 0.1rem 0.1rem 0.5rem black" }}>&nbsp;</div>
                                    </div>
                                </div>
                            );
                        })
                        :
                        <>
                            <br />
                            <h1 style={{ textAlign: "center" }}> ⚠️ No Records Available.</h1>
                        </>
                }
            </div>
        </div>
    );
}

export default UnhandledAnalytics;