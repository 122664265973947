/**
 * Compoent that will help in uploading model training sheet for bulk utterance model training
 * - first user will provide sheet
 * - sending whole sheet to backend for further data verification containing that sheet
 * - once everything looks good
 * - this component will receive the data of that sheet
 */

import React from "react";

import fetchData from "../../../data/fetchData";

const ProcessUtteraceSheet = (props) => {

    const handleSheetUploadForModelTraining = async (e) => {
        try {
            console.log("handleSheetUploadForModelTraining fn called : ", e);
            e.preventDefault();

            console.log(e.target);

            const form = e.target;

            const file = form[0].files[0];
            const file_ext = file.name.split(".")[1];

            if (file_ext !== "xlsx") {
                alert("kya kar rahe ho ? Sahi file upload karo bhaya! Model ki training chal rahi hai, koi khilwad nahi hai ye. Do it with all responsibilty.");
                return null;
            }

            console.log(file_ext);

            let formData = new FormData();
            formData.append("model_tr_xlsx_file", file);
            formData.append("training_type", props.training_type);


            const res = await fetchData("POST", `${process.env.REACT_APP_SERVER_URL}/upload_model_tr_file`, {
                type: "upload_file",
                body: formData
            });

            console.log(res);

            if (res && res.status === "OK") {
                props.callback(res.data);
            } else {
                alert("Something went wrong...");
            }

        } catch (err) {
            console.log(err);
        }
    }

    // Download sample high intent
    const downloadSampleSheetForHighIntentTraining = async () => {
        try {

            let sheetToBeDownload = "";

            if (props.training_type === "high_intent") {
                sheetToBeDownload = "HighIntentTrainingSample.xlsx";
            } else {
                sheetToBeDownload = "MidIntentTrainingSample.xlsx";
            }

            const data = await fetchData("GET", sheetToBeDownload, null, "blob");

            console.log(sheetToBeDownload, data);
            
            const blobURL = window.URL.createObjectURL(new Blob([data]));

            const a = document.createElement("a");
            a.href = blobURL;
            const file_name = "sample_file_" + props.training_type + ".xlsx";
            a.setAttribute("download", file_name);
            a.setAttribute("target", "_blank");
            document.body.appendChild(a);
            a.click();
            a.remove();

        } catch (err) {
            console.log(err);
        }
    }

    return (
        <div style={{ display: "flex", width: "70%", flex: 1, justifyContent: "space-between" }}>
            <form onSubmit={handleSheetUploadForModelTraining} encType="multipart/form-data" style={{ display: "flex", width: "70%", justifyContent: "space-between" }}>
                <input required type="file" accept=".xlsx" style={{ fontWeight: "bold", fontSize: "1.1rem", background: "none", border: "0.1rem solid green", padding: "0.5rem 2rem", borderRadius: "5rem", cursor: "pointer", width: "55%" }} />
                <button type="submit" style={{ fontWeight: "bold", fontSize: "1.1rem", color: "white", background: "green", border: "0.3rem solid green", padding: "0.5rem 2rem", borderRadius: "5rem", cursor: "pointer", width: "25%" }}>Upload Sheet</button>
            </form>


            {/* Download Sample */}
            <button onClick={downloadSampleSheetForHighIntentTraining} style={{ width: "auto", padding: "0.5rem", border: "0.1rem solid grey" }}>Download Sample Sheet</button>

        </div>
    );
}

export default ProcessUtteraceSheet;