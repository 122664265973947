import React, { useEffect, useState } from "react";

import { default as tc } from "../assets/json/test-cases.json";

import "../styles/testing.css";

import oriLogoSolo from "../assets/images/ori-logo-solo.png";
import Header from "../components/Header";
import fetchData from "../data/fetchData";

const TestingEbot = () => {

    const [useCaseSearchText, setUseCaseSearchText] = useState("");

    const [testUrl, setTestUrl] = useState();

    const [cachedMailTrackId, setCachedMailTrackId] = useState("");

    const [testBody, setTestBody] = useState({ channel: "WebHook" });
    const [testBodyJSON, setTestBodyJSON] = useState("{}");

    const [testResponse, setTestResponse] = useState({});
    const [testInspectTrackId, setTestInspectTrackId] = useState("");

    const [testCases, setTestCases] = useState(tc);
    const [testDupCases, setTestDupCases] = useState(tc);

    const [showSpinner, setShowSpinner] = useState(false);
    const [errMsg, setErrMsg] = useState("");

    const [historyLimit, setHistoryLimit] = useState(0);

    const [mailDataType, setMailDataType] = useState("simple");

    const [startPolling, setStartPolling] = useState(false);
    const [trackIdForPolling, setTrackIdForPolling] = useState("");

    const loadTestMailhistory = async () => {
        try {
            setShowSpinner(true);

            const limit = historyLimit + 10;
            const skip = historyLimit;

            const raw_res = await fetch(`${process.env.REACT_APP_MICROSERVER_URL}/test-mails?limit=${limit}&skip=${skip}`);
            const res = await raw_res.json();

            console.log("response fo api for getting all the mails : ", res);

            // setTotalHistoryLimit(res.totalDocs);

            let cases = res.data.map(({ request }) => ({
                useCase: request.subject,
                to: request.to,
                from: request.from,
                subject: request.subject,
                content: request.content,
                channel: request.channel,
                trackId: request?.trackId ?? "",
                label: request?.label ?? "",
            }));

            console.log("case s : ", cases);

            setTestCases([...testCases, ...cases]);
            setTestDupCases([...testCases, ...cases]);

            setHistoryLimit(limit);

            setShowSpinner(false);

        } catch (err) {
            setShowSpinner(false);
            // console.log(err);
        }
    }

    const showTestMailhistory = () => {

    }

    const saveTestingData = async (data) => {
        try {
            // console.log("saveTestingData fn called...", data);

            const res = await fetchData("POST", `${process.env.REACT_APP_MICROSERVER_URL}/save-test-mails`, data);

            // console.log(res);

        } catch (err) {
            console.error("Error = ", err);
        }
    }

    const changeTestUrl = (e) => {

        // console.log("location: ", window.location.hostname)

        switch (window.location.hostname) {
            case "localhost":
                setTestUrl("http://localhost:8000/")
                break;

            case "vil-email-sprint-dashboard.oriserve.com":
                setTestUrl("https://vil-email-sprint.oriserve.com/")
                break;

            case "vil-email-dev-dashboard.oriserve.com":
                setTestUrl("https://vil-email-dev.oriserve.com/")
                break;

            case "vil-email-dashboard.oriserve.com":
                setTestUrl("https://vil-email.oriserve.com/")
                break;

            default:
                setTestUrl("https://vil-email-dev.oriserve.com/")
                break;
        }
    }

    const changeBodyTextJSON = (e) => {

        if (e.target.value) {
            try {

                let data = JSON.parse(e.target.value);

                setTestBody(data);
                setTestBodyJSON(e.target.value);
                setErrMsg("");

            } catch (err) {
                setErrMsg("❌ " + err.message);
                setTestBodyJSON(e.target.value);
            }
        }
    }

    const setUseCaseBodyDataForTest = (mail) => {

        // console.log("cachedMailTrackId : mai: ", mail)

        let obj = {
            to: mail.to,
            from: mail.from,
            subject: mail.subject,
            content: mail.content,
            channel: mail?.channel ?? "WebHook",
            trackId: mail.trackId ?? null
        }

        setCachedMailTrackId(mail?.trackId);
        setTestBody(obj);
        setTestBodyJSON(JSON.stringify(obj, null, 4));

    }

    const reset = () => {
        setTestBody({
            to: "",
            from: "",
            subject: "",
            content: "",
            channel: "WebHook",
            trackId: "",
        });
        setTrackIdForPolling("");
        setCachedMailTrackId("");
    }

    const testMail = async (toBeSaved = false) => {
        try {
            // console.log("Test Mail fn called...");

            setShowSpinner(true);

            let testBodyObj;

            try {
                testBodyObj = testBody;
            } catch (err) {
                setErrMsg("❌ " + err.message);
                setShowSpinner(false);
                return;
            }

            setErrMsg("");

            if (!testUrl || !testBodyObj.from || !testBodyObj.to || !testBodyObj.content || !testBodyObj.channel || !testBodyObj.subject) {
                alert("Not sufficient data for testing the mail use case.");
                setShowSpinner(false);

                return;
            }
            // console.log("body for mail cachedMailTrackId: ", cachedMailTrackId);

            const body = JSON.stringify({ ...testBodyObj, trackId: cachedMailTrackId });

            // console.log("body for mail process: ", body);

            const raw_res = await fetch(testUrl + "processEmailCached", {
                method: "POST",
                body,
                headers: {
                    "Content-Type": "application/json"
                }
            })

            const res = await raw_res.json();

            // console.log(res);

            if (res.data.trackingId) {
                setStartPolling(true);
                setTrackIdForPolling(res.data.trackingId);
            } else {
                setStartPolling(false);
                setTrackIdForPolling("");
            }

            setTestResponse(res);
            setTestInspectTrackId(res.data.trackingId);
            setShowSpinner(false);

            // console.log("toBeSaved = ", toBeSaved);

            if (toBeSaved) saveTestingData({ request: testBodyObj, response: res });

        } catch (err) {
            // console.log(err);
            setErrMsg("❌ " + err.message);
            setShowSpinner(false);
        }
    }

    const beutifyBody = () => {
        try {

            setTestBody(JSON.stringify(JSON.parse(testBody), null, 2));

        } catch (err) {
            alert("Kindly check your JSON body whether it is correct or not before beutifying the body!")
        }
    }

    const changeMailData = (type, value) => {

        const obj1 = { ...testBody };
        obj1[type] = value;

        setTestBodyJSON(JSON.stringify(obj1, null, 4));
        setTestBody(obj1);
    }

    useEffect(() => {
        document.title = "Email Bot test mail cases";

        changeTestUrl();

        loadTestMailhistory();

        return () => {
            document.title = "Email Bot Dashboard";
        };
    }, []);

    useEffect(() => {

        if (startPolling) {
            const pollingRef = setInterval(async () => {

                const res = await fetchData("GET", testUrl + "getEmailData?trackId=" + trackIdForPolling);

                if (res?.data?.processingStatus === "completed") {
                    setTestResponse(res);
                    clearInterval(pollingRef);
                    setStartPolling(false);
                }

            }, 5000);
        }

    }, [startPolling]);

    return (
        <>

            {
                showSpinner ?
                    <>
                        {/* Spinner for time taken processes */}
                        <div style={{ zIndex: 99999, position: "fixed", display: "grid", placeItems: "center", top: 0, left: 0, width: "100%", height: "100%", background: "rgba(255, 255, 255, 0.8)", backdropFilter: "blur(8px)" }}>
                            <img alt="testing-ebot" className="spinner-image" src={oriLogoSolo} style={{ width: "auto", height: "20%" }} /> <br />
                            <h1>In Progress...</h1>
                            <progress></progress>
                        </div>
                    </>
                    :
                    null
            }

            <div className="TestContainer">

                {/* Header Area Started */}
                <Header
                    child={
                        <div className="test-container-header">

                            {/* Container Header Test, Environment selection */}
                            <div className="test-container-header-child1">
                                <div style={{ width: "50%" }}>
                                    <h1>Test Mail Cases </h1>
                                </div>
                                {/* 
                                <div style={{ width: "50%" }}>
                                    <h2>Test Environment</h2>
                                    <select defaultValue={"dev"} onChange={changeTestUrl}>
                                        <option value={"localhost"}>Local Server</option>
                                        <option value={"sprint"}>Sprint server</option>
                                        <option value={"dev"}>Development server</option>
                                        <option value={"prod"}>Production server</option>
                                    </select>
                                </div> */}
                            </div>

                            {/* Container Load history Button, another show history view component in mobile view */}
                            {/* <div className="test-container-header-child2">
                                <div style={{ width: "50%" }}>
                                    <button style={{ padding: "0.5rem", cursor: "pointer" }} onClick={loadTestMailhistory}>Load History</button>
                                </div>

                                <div style={{ width: "50%" }}>
                                    <button style={{ padding: "0.5rem", cursor: "pointer" }} onClick={showTestMailhistory}>Show History</button>

                                </div>
                            </div> */}
                        </div>
                    }
                />
                {/* Header Area Ended */}

                <div className="auto-select-case-from-list">

                    {/* Div 1 : sidebar for selection of case */}
                    <div className="auto-select-case-from-list-child-1">

                        <div className="text-search-area">

                            <input type="text"
                                placeholder="Enter keywords from subject / content"
                                value={useCaseSearchText}
                                onChange={(e) => {
                                    setUseCaseSearchText(e.target.value);

                                    if (e.target.value === "") {
                                        setTestCases(testDupCases);
                                    }

                                    let filteredTestCases = testDupCases.filter((mail) => {
                                        if (mail.subject.toLowerCase().includes(e.target.value) || mail.content.toLowerCase().includes(e.target.value)) {
                                            return true;
                                        }
                                        return false;
                                    })

                                    setTestCases(filteredTestCases);

                                }}
                            />

                        </div>

                        <div style={{ padding: "0 1rem" }}>
                            {
                                testCases?.map((mail) => {
                                    console.log("mail: ", mail);

                                    return (
                                        <div title={mail.label} onClick={() => setUseCaseBodyDataForTest(mail)} style={{ cursor: "pointer", backgroundColor: "white", padding: "1rem", margin: "1rem 0", borderRadius: "0.5rem", boxShadow: "0.1rem 0.1rem 0.5rem lightgrey" }}>
                                            <p>{mail.label}</p>
                                        </div>
                                    );
                                })
                            }
                        </div>

                    </div>

                    {/* Div 2 for details and do the api call */}
                    <div className="auto-select-case-from-list-child-2" style={{ display: "flex", flexDirection: "column", justifyContent: "flex-start" }} >

                        {/* mail data simple and json format */}
                        {/* <div style={{ margin: "1rem 0", display: "flex", justifyContent: "space-between" }}>

                            <p style={{ height: "1.5rem", fontSize: "1.3rem", display: "flex", width: "40%", justifyContent: "space-between", alignItems: "center" }}>

                                <h3>Mail Test Type : </h3>

                                <div style={{ display: "flex", width: "50%", justifyContent: "space-between", alignItems: "center" }}>
                                    <label style={{ display: "flex", alignItems: "center", width: "50%", justifyContent: "space-between" }} onClick={() => setMailDataType("simple")} >

                                        {
                                            mailDataType === "simple" ?
                                                <input type="radio" name="mailData" checked />
                                                :
                                                <input type="radio" name="mailData" />
                                        }

                                        <p>Simple</p>
                                    </label>

                                    <label style={{ display: "flex", width: "50%", justifyContent: "space-between" }} onClick={() => setMailDataType("json")}>
                                        <input type="radio" name="mailData" />
                                        <p>JSON</p>
                                    </label>
                                </div>

                            </p>

                            <p style={{ color: "red" }}>{errMsg}</p>

                            {
                                mailDataType === "json" ?
                                    <button onClick={beutifyBody} style={{ padding: "0.4rem", cursor: "pointer" }}>Beautify</button>
                                    :
                                    null
                            }

                        </div> */}

                        {

                            mailDataType === "simple" ?
                                <>
                                    {/* <hr style={{ border: "0.01rem solid lightgrey" }} /> */}
                                    <div style={{ padding: "1.5rem 0", display: "flex", width: "100%", height: "auto", justifyContent: "space-between" }}>

                                        <div style={{ width: "35%", display: "flex", flexDirection: "column" }}>

                                            <div>
                                                <p style={{ fontSize: "1.3rem" }}>Channel</p>
                                                <input type="text" onChange={(e) => changeMailData("channel", e.target.value)} value={testBody.channel} />
                                            </div>
                                            <br />

                                            <div>
                                                <p style={{ fontSize: "1.3rem", marginBottom: "0.5rem" }}>To</p>
                                                <input type="text" placeholder="Enter target email" onChange={(e) => changeMailData("to", e.target.value)} value={testBody.to} />
                                            </div>
                                            <br />

                                            <div>
                                                <p style={{ fontSize: "1.3rem", marginBottom: "0.5rem" }}>From</p>
                                                <input type="text" placeholder="Enter source email" onChange={(e) => changeMailData("from", e.target.value)} value={testBody.from} />
                                            </div>
                                            <br />

                                            <div>
                                                <p style={{ fontSize: "1.3rem", marginBottom: "0.5rem" }}>Subject</p>
                                                <input type="text" placeholder="Enter subject" onChange={(e) => changeMailData("subject", e.target.value)} value={testBody.subject} />
                                            </div>

                                        </div>

                                        <div style={{ display: "flex", width: "62%", height: "auto" }}>

                                            <div style={{ display: "flex", flexDirection: "column", flex: 1 }}>
                                                <p style={{ fontSize: "1.3rem", marginBottom: "0.5rem" }}>Body</p>
                                                <textarea placeholder="Enter mail body..." style={{ flex: 1, padding: "1rem" }} onChange={(e) => changeMailData("content", e.target.value)} value={testBody.content} ></textarea>
                                            </div>
                                        </div>

                                    </div>

                                </>
                                :
                                <>
                                    <textarea spellCheck="false" onChange={changeBodyTextJSON} value={testBodyJSON} style={{ whiteSpace: "pre", width: "100%", flex: 0.7, resize: "vertical", padding: "1rem", outline: "none" }}> </textarea>
                                    <br />
                                </>
                        }


                        {/* Url input Box, test + save & test buttons  */}
                        <div style={{ display: "flex", justifyContent: "flex-end" }}>
                            {/* <input type="text" value={testUrl + "processEmail"} style={{ outline: "none" }} /> */}
                            {/* <button onClick={() => testMail(true)} style={{ marginLeft: "1rem", backgroundImage: "linear-gradient(to bottom right, #50C878, #98FB98, lightgreen)", padding: "0.6rem", width: "16rem", borderRadius: "0.3rem", border: "0.01rem solid grey", cursor: "pointer" }}>Test & Save</button> */}

                            {
                                !startPolling ?
                                    <>
                                        <button onClick={reset} style={{ marginLeft: "1rem", backgroundImage: "linear-gradient(to bottom right, #50C878, #98FB98, lightgreen)", padding: "0.6rem", width: "10rem", borderRadius: "0.3rem", border: "0.01rem solid grey", cursor: "pointer", background: "none" }}>Reset</button>
                                        <button onClick={() => testMail(false)} style={{ marginLeft: "1rem", backgroundImage: "linear-gradient(to bottom right, #50C878, #98FB98, lightgreen)", padding: "0.6rem", width: "10rem", borderRadius: "0.3rem", border: "0.01rem solid grey", cursor: "pointer" }}>Test</button>
                                    </>
                                    :
                                    null
                            }

                        </div>

                        {/* Email bot result Data Area: */}
                        <div style={{ margin: "1rem 0", display: "flex", justifyContent: "flex-start", alignItems: "center" }}>
                            <h2 style={{ width: "20%" }}>Result</h2>
                            {/* <div style={{ width: "50%", display: "flex", justifyContent: "space-around" }}>
                                <button onClick={() => window.open(testUrl + "admin/diagnoseEmail?trackId=" + testInspectTrackId)} style={{ padding: "1rem", border: "0.1rem solid grey", cursor: "pointer" }}>Inspect 🔗</button>
                                <button onClick={() => window.open(testUrl + "getEmailData?trackId=" + testInspectTrackId)} style={{ padding: "1rem", border: "0.1rem solid grey", cursor: "pointer" }}>Output</button>
                            </div> */}

                            {
                                testResponse?.data?.hasOwnProperty("endTemplate") ?
                                    <>
                                        <h1 className="tag" style={{
                                            fontSize: "1.2rem",
                                            color: testResponse?.data?.responseMode === "AUTO" ? "white" : testResponse?.data?.responseMode === "AGENT_ASSISTED" ? "white" : "black",
                                            backgroundColor: testResponse?.data?.responseMode === "AUTO" ? "green" : testResponse?.data?.responseMode === "AGENT_ASSISTED" ? "orange" : "pink",
                                        }}>
                                            {testResponse?.data?.responseMode}
                                        </h1>
                                        <h1 className="tag" style={{ fontSize: "1.2rem" }}>{testResponse?.data?.processingStatus}</h1>
                                    </>
                                    :
                                    startPolling ?
                                        <>
                                            <img alt="testing-ebot" className="spinner-image" src={oriLogoSolo} style={{ width: "2rem", height: "2rem", marginRight: "1rem" }} /> <br />
                                            <p>We are fetching your mail processing result...</p>
                                        </>
                                        :
                                        null
                            }

                        </div>

                        {
                            testResponse?.data?.processingStatus === "completed" ?
                                <>
                                    <textarea spellCheck="false" value={testResponse.data.endTemplate === "" ? "No Output Found." : testResponse.data.endTemplate} style={{ width: "100%", flex: 1, resize: "vertical", padding: "1rem", outline: "none" }}></textarea>
                                </>
                                :
                                testResponse?.data?.trackingId ?
                                    <textarea spellCheck="false" value={"Great Your mail is under processing.\n\nPlease note your track id for the future referance.\nYour Tracking id is : " + testResponse?.data?.trackingId + "\n\nYou may wait here while we are fetching the mail processing results..."} style={{ width: "100%", flex: 1, resize: "vertical", padding: "1rem", outline: "none" }}></textarea>
                                    :
                                    <textarea spellCheck="false" value={"Mail Processing results will be logged here..."} style={{ width: "100%", flex: 1, resize: "vertical", padding: "1rem", outline: "none" }}></textarea>
                        }

                    </div>

                </div>

            </div>
        </>
    );
}

export default TestingEbot;