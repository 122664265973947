import React, { useEffect, useState } from "react";
import "./css/header.css";
import { useNavigate } from "react-router-dom";

import { checkIsLoggedIn } from "../App";
import Feedback from "./feedback";

const Header = (props) => {

    // feedback
    const [showFeedbackModal, setShowFeedbackModal] = useState(false);

    const [showProfileModal, setShowProfileModal] = useState(false);
    const [profile, setProfile] = useState({});
    const navigate = useNavigate();

    const logout = () => {
        localStorage.setItem("loginDetails", "");
        navigate(0);
    }

    const changeTheme = () => {

        const divs = document.getElementsByTagName("div");
        const ps = document.getElementsByTagName("p");
        const h1s = document.getElementsByTagName("h1");
        const h2s = document.getElementsByTagName("h2");
        const spans = document.getElementsByTagName("span");
        const as = document.getElementsByTagName("a");
        const imgs = document.getElementsByTagName("img");
        for (let i = 0; i < divs.length; ++i) divs[i].style.backgroundColor = "#282828";
        for (let i = 0; i < ps.length; ++i) ps[i].style.color = "white";
        for (let i = 0; i < h1s.length; ++i) h1s[i].style.color = "white";
        for (let i = 0; i < h2s.length; ++i) h2s[i].style.color = "white";
        for (let i = 0; i < spans.length; ++i) spans[i].style.color = "white";
        for (let i = 0; i < imgs.length; ++i) imgs[i].style.color = "white";
        for (let i = 0; i < as.length; ++i) {
            imgs[i].style.color = "white";
            imgs[i].style.backgroundColor = "#282828";
        }

    }

    useEffect(() => {
        const data = checkIsLoggedIn();

        if (data) {
            setProfile(data);
        }
    }, [])

    return (
        <>
        
            {
                showFeedbackModal
                    ?

                    <>
                        <Feedback
                            open={(res) => {
                                setShowProfileModal(false);
                                setShowFeedbackModal(res)
                            }}
                        />
                    </>

                    :

                    null
            }


            {
                props.child ?
                    <>
                        <div className="header-container">
                            <div>
                                {props.child}
                            </div>
                            <div>
                                <div style={{ display: "grid", placeItems: "center", position: "relative" }}>
                                    <h2 onClick={() => setShowProfileModal(!showProfileModal)} style={{ cursor: "pointer", display: "grid", placeItems: "center", width: "3rem", height: "3rem", borderRadius: "5rem", background: "linear-gradient(to bottom right, #50C878, #98FB98, lightgreen)", border: "0.2rem solid green" }}>{profile?.name ? profile?.name[0] : "P"}</h2>

                                    {
                                        showProfileModal ?
                                            <>
                                                {/* Modal for Profile */}
                                                <div style={{ zIndex: 9999999999, position: "absolute", top: "100%", display: "flex", flexDirection: "column", backgroundColor: "white", width: "30rem", height: "30rem", boxShadow: "0.1em 0.1rem 1rem grey", borderRadius: "0.5rem", padding: "2rem", right: 0, justifyContent: "space-between" }}>
                                                    <div>
                                                        <p onClick={() => setShowProfileModal(!showProfileModal)} style={{ cursor: "pointer", fontSize: "3rem", textAlign: "right" }}>&times;</p>
                                                        <p style={{ fontSize: "1.6rem" }}>Welcome, <h2>{profile ? profile.name : ""}</h2></p>
                                                        <p><h2>{profile ? profile.email : ""}</h2></p>
                                                        <br />
                                                        <br />
                                                        <h3 style={{ padding: "0.5rem", cursor: "pointer", width: "50%", borderRadius: "5rem", textAlign: "center", backgroundColor: "lightgreen" }} onClick={logout}> Log Out  🧨</h3>
                                                        <br />
                                                        <br />
                                                        <h3 style={{ padding: "0.5rem", cursor: "pointer", width: "70%", borderRadius: "5rem", textAlign: "center", backgroundColor: "lightgreen" }} onClick={changeTheme}> Change Theme (EXP.) </h3>
                                                    </div>

                                                    <div>
                                                        <p style={{ cursor: "pointer", textAlign: "center" }} onClick={() => {
                                                            setShowProfileModal(false);
                                                            setShowFeedbackModal(true);
                                                        }} >Feedback</p>
                                                    </div>
                                                </div>
                                            </>
                                            :
                                            null
                                    }
                                </div>

                            </div>
                        </div>

                    </>
                    :
                    <>
                        <div style={{ zIndex: 9999, backgroundColor: "white", padding: "1.5%", boxShadow: "0.1rem 0.1rem 0.5rem lightgrey", position: "sticky", top: 0, display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                            <h1>{props.heading}</h1>
                        </div>
                    </>
            }
        </>
    );
}

export default Header;