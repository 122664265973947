import React, { useCallback, useEffect, useState } from "react";
import ReactFlow, { applyNodeChanges, applyEdgeChanges, Background, MiniMap, Controls } from 'reactflow';
import 'reactflow/dist/style.css';

import { useParams } from "react-router-dom";
import Header from "../../components/Header";
import fetchData from "../../data/fetchData";

const initialNodes = [
    {
        id: '1',
        type: 'input',
        data: { label: <h1>Loading...</h1> },
        position: { x: 200, y: 25 },
    },
];

const initialEdges = [
];

const Workflow = () => {
    const params = useParams();

    const [nodes, setNodes] = useState(initialNodes);
    const [edges, setEdges] = useState(initialEdges);

    const [editingNodeId, setEditingNodeId] = useState(0);
    const [editingWid, setEditingWid] = useState(0);
    const [showNodeEditingModal, setShowNodeEditingModal] = useState(false);
    const [currNodeDetails, setCurrNodeDetails] = useState({});

    const [currWorkflowDetails, setCurrWorkflowDetails] = useState({});

    const [WUIRepresentation, setWUIRepresentation] = useState({});

    console.log("WUIRepresentation = ", WUIRepresentation);

    const onNodesChange = useCallback((changes) => {
        // console.log(changes);
        setNodes((nodes) => applyNodeChanges(changes, nodes))
    }, [setNodes]);

    const onEdgesChange = useCallback((changes) => {
        setEdges((edges) => applyEdgeChanges(changes, edges))
    }, [setEdges]);

    const handleNodeDragStop = (moudeEvent, node) => {
        console.log("node - ", node);
        WUIRepresentation[node.id] = node?.position ?? {};
    }

    const getWorkflow = async () => {
        try {
            const data = await fetchData("GET", `${process.env.REACT_APP_SERVER_URL}/getWorkflow/${params.id}`);

            console.log("data - ", data);

            let UIRepresentation = data?.workflow?.UIRepresentation ?? {};

            if (!data) {
                alert("Something went wrong");
                return null;
            }

            let new_edges = [];

            let new_nodes = data.nodes.map((node, index) => {

                let edges = data.edges.filter((edge) => node.nodeId === edge.source.nodeId);

                let cool_edges = edges.map((edge, index) => {
                    return {
                        id: `e${edge.source.wid}.${edge.source.nodeId}-${edge.target.wid}.${edge.target.nodeId}`,
                        source: `${edge.source.nodeId}`,
                        target: `${edge.target.nodeId}`,
                        animated: true,
                        label: edge.rule
                    }
                })

                new_edges = [...new_edges, ...cool_edges];

                const node_position = { x: UIRepresentation[node.nodeId]?.x ? UIRepresentation[node.nodeId].x : (index + 1) * 100, y: UIRepresentation[node.nodeId]?.y ? UIRepresentation[node.nodeId].x : (index + 1) * 50 };

                console.log("NODE ID = ", node.nodeId);
                console.log("node_position = ", node_position);

                console.log("X = ", UIRepresentation[node.nodeId]?.x);
                console.log("X = ", UIRepresentation[node.nodeId]?.y);

                return {
                    id: `${node.nodeId}`,
                    data: {
                        label: <div style={{ display: "flex", flexDirection: "column", justifyContent: "flex-start" }} onDoubleClick={() => {
                            setShowNodeEditingModal(true);
                            setEditingWid(params.id);
                            setEditingNodeId(node.nodeId);
                            setCurrNodeDetails(node);
                        }}>
                            <div style={{ width: "100%", display: "flex", justifyContent: "space-between" }}>
                                <h3 style={{ borderRadius: "5rem", textAlign: "center", padding: "0.5rem", width: "11rem", background: "linear-gradient(to bottom right, #50C878, #98FB98, lightgreen)" }}>Node ID : {node.nodeId} </h3>
                                <h2 onClick={() => {
                                    setShowNodeEditingModal(true);
                                    setEditingWid(params.id);
                                    setEditingNodeId(node.nodeId);
                                    setCurrNodeDetails(node);
                                }}>⚒️</h2>
                            </div>

                            <div style={{ height: "8rem", padding: "1rem" }}>
                                <p style={{ fontSize: "1.7rem", overflow: "hidden" }} title={node.action}>{node.action} </p>
                            </div>

                            <div style={{ width: "auto" }}>
                                {
                                    node.variable ?

                                        <p style={{ borderRadius: "5rem", textAlign: "center", padding: "0.5rem", backgroundColor: "blue", color: "white", overflow: "hidden" }} title={node.variable}>{node.variable} </p>
                                        :
                                        null
                                }
                            </div>

                        </div>
                    },
                    position: node_position,
                    style: { boxShadow: "0.1rem 0.1rem 1rem grey", textAlign: "left", width: "250px", height: "auto", border: `0.2rem solid ${node.end ? "red" : node.start ? "blue" : "green"}` }
                };
            })

            setCurrWorkflowDetails(data.workflow);
            setNodes(new_nodes);
            setEdges(new_edges);
            setWUIRepresentation(UIRepresentation);

        } catch (err) {
            console.log("Error = ", err);
        }
    }

    const saveWUIRepresentation = async () => {
        try {
            const wf = await fetchData("POST", `${process.env.REACT_APP_SERVER_URL}/modifyWorkflow/${params.id}`, { WUIRepresentation });

            console.log("Workflow modification res = ", wf);

        } catch (err) {
            console.log("Error in saveWUIRepresentation : ", err);
        }
    }

    window.addEventListener("keydown", (e) => {
        if (e.key === "Escape") {
            setShowNodeEditingModal(false);
        }
    });

    useEffect(() => {
        getWorkflow(); // eslint-disable-next-line
    }, []);

    return (
        <>
            {
                showNodeEditingModal ?
                    <>
                        <div style={{ display: "grid", placeItems: "center", zIndex: 999999, position: "fixed", background: "rgba(255, 255, 255, 0.4)", backdropFilter: "blur(10px)", width: "100%", height: "100%", top: 0, left: 0 }}>

                            <h1 onClick={() => setShowNodeEditingModal(false)} style={{ cursor: "pointer", position: "absolute", top: "2%", right: "2%", fontSize: "3rem" }}>&times;</h1>

                            <fieldset style={{ boxShadow: "0.5rem 0.5rem 1rem lightgrey", borderRadius: "1rem", border: "0.1rem solid lightgrey", outline: "none", backgroundColor: "white", width: "90%", height: "95%", padding: "1rem", fontSize: "1.5rem" }}>

                                {/* Legend Tag -> details about node */}
                                <legend style={{ marginLeft: "2rem", color: "white", width: "20%", fontSize: "1.6rem", borderRadius: "4rem", display: "flex", backgroundColor: "red", padding: "1%", justifyContent: "space-evenly" }}>
                                    <h3>Wid: {editingWid}</h3>
                                    <h3>Node Id: {editingNodeId}</h3>
                                </legend>


                                {/* Main Div Container */}
                                <div style={{ display: "flex", minHeight: "100%", justifyContent: "space-between" }}>

                                    {/* Div - 1 for general details */}
                                    <div style={{ width: "30%", overflow: "auto", border: "0.2rem solid lightgrey", padding: "1.5rem", borderRadius: "0.3rem" }}>
                                        <h1>Node Details </h1><br /><hr /><br />
                                        <p> Name : {currNodeDetails.name} </p> <br />
                                        <p> Action : {currNodeDetails.action} </p>  <br />
                                        <p> Variable : {currNodeDetails.variable} </p>

                                        <br />
                                        <h1>Edges</h1>

                                        {/* showing edge here for visualization */}
                                        {
                                            edges.filter((edge) => +edge.source === currNodeDetails.nodeId)?.map((edge) => {
                                                return (
                                                    <div style={{ borderRadius: "0.3rem", margin: "1rem 0%", border: "0.1rem solid grey", padding: "3%" }}>
                                                        <div style={{ display: "flex", justifyContent: "space-between" }}>
                                                            <p>Node Id: {edge.source}</p>
                                                            <p>&rsaquo;</p>
                                                            <p>Node Id: {edge.target}</p>
                                                        </div>
                                                        <hr style={{ margin: "1rem 0" }} />
                                                        <p>Rule : {edge.label}</p>
                                                    </div>
                                                );
                                            })
                                        }
                                    </div>

                                    {/* Div - 2 for coding details */}
                                    <div style={{ width: "69%", display: "flex", overflow: "auto", flexDirection: "column" }}>
                                        <h1>Action Body </h1><br />
                                        {/* <h1>Coding Details : </h1> */}
                                        <textarea spellCheck="false" style={{ whiteSpace: "pre", outline: "none", flex: 1, width: "100%", border: "0.2rem solid lightgrey", padding: "1.5rem", borderRadius: "0.3rem" }}>
                                            {"const " + currNodeDetails.action + " = " + currNodeDetails.action_code}
                                        </textarea>

                                    </div>

                                </div>

                                {/* Main Div container details */}

                            </fieldset>

                        </div>
                    </>
                    : null
            }

            <Header
                child={
                    <div style={{ display: "flex", justifyContent: "space-between", width: "80%" }}>
                        <h1>WID - {params.id}, {currWorkflowDetails.name}</h1>
                        {/* <button onClick={saveWUIRepresentation} title="Note: by clicking on this save button only UI representation will be saved as of now." className="download-button">Save</button> */}
                    </div>
                }
            />

            <ReactFlow
                nodes={nodes}
                onNodesChange={onNodesChange}
                edges={edges}
                onEdgesChange={onEdgesChange}
                onNodeDragStop={handleNodeDragStop}
            >
                <Background />
                <MiniMap />
                <Controls />
            </ReactFlow>
        </>
    );
}

export default Workflow;