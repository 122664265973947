import Workflow_icon from "../assets/images/workflows.png";
import ai_intent from "../assets/images/ai-intent.png";
import apis_icon from "../assets/images/apis.png";
import templates_icon from "../assets/images/templates.png";
import history_icon from "../assets/images/history_icon.png";
import history from "../assets/images/history.png";
import testing_icon from "../assets/images/testing_icon.png";
import processing_icon from "../assets/images/processing_icon.png";
import documentation_icon from "../assets/images/documentation.png";
import test_mail_icon from "../assets/images/test-mail.png";
import ai_training_icon from "../assets/images/ai-training.png";

const icons = {
    Workflows: Workflow_icon,
    Intents: ai_intent,
    Templates: templates_icon,
    Report: history_icon,
    History: history,
    APILogs: apis_icon,
    Numbers: testing_icon,
    Process: processing_icon,
    AI: ai_training_icon,
    TestMail: test_mail_icon,
    docs: documentation_icon,
}

export {
    icons
}