import React, { useEffect, useState } from "react";
import Header from "../components/Header";
import fetchData from "../data/fetchData";
import "../styles/templates.css";

const Templates = () => {

    const [templates, setTemplates] = useState([]);
    const [dupTemplates, setDupTemplates] = useState([]);

    const [showErrorMessage, setShowErrorMessage] = useState(false);

    const [showModal, setShowModal] = useState(false);
    const [currTemplate, setCurrTemplate] = useState({});

    const [searchTemplateByName, setSearchTemplateByName] = useState("Template Name");

    const [skip, setSkip] = useState(0);

    const [totalTemplates, setTotalTemplates] = useState("");

    const getTemplates = async () => {
        try {

            if (totalTemplates === templates.length) {
                // console.log("Aleardy data fetched.")
                return false;
            }

            const data = await fetchData("GET", `${process.env.REACT_APP_SERVER_URL}/templates?skip=${skip}`);

            // console.log("templates = ", data);

            if (data && data.templates && data.templatesCount && localStorage) {

                const updated_templates = [...templates, ...data.templates];

                setTemplates(updated_templates);
                setDupTemplates(updated_templates);
                setTotalTemplates(data.templatesCount);
                localStorage.setItem("templates", JSON.stringify(updated_templates));

            } else {
                setShowErrorMessage(true);
            }

        } catch (err) {
            // console.log(err);
            setTemplates([]);
            setShowErrorMessage(true);
        }
    }

    window.addEventListener("keydown", (e) => {
        if (e.key === "Escape") {
            setShowModal(false);
        }
    });

    const loadNextBatchOfTemplateData = (e) => {
        try {
            const { offsetHeight, scrollTop, scrollHeight } = e.target;

            // console.log("offsetHeight+scrollTop, scrollHeight = ", offsetHeight + scrollTop, scrollHeight);

            if (offsetHeight + scrollTop === scrollHeight - 1) {
                setSkip(templates.length);
            }
        } catch (err) {
            console.log(err);
        }
    }

    const downloadTemplates = async () => {
        try {
            const data = await fetchData("GET", `${process.env.REACT_APP_SERVER_URL}/downloadTemplate`, null, "blob");

            const blobURL = window.URL.createObjectURL(new Blob([data]));

            const a = document.createElement("a");
            a.href = blobURL;
            const file_name = "templates.xlsx";
            a.setAttribute("download", file_name);
            a.setAttribute("target", "_blank");
            document.body.appendChild(a);
            a.click();
            a.remove();

        } catch (err) {
            console.log(err);
        }
    }

    useEffect(() => {
        let localData = localStorage.getItem("templates");
        if (localData) setTemplates(JSON.parse(localData));
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        getTemplates();
    }, [skip]); // eslint-disable-line react-hooks/exhaustive-deps

    return (
        <div style={{ display: "flex", height: "100%", width: "100%", flexDirection: "column", flex: 1 }}>

            {/* Header Area Started */}

            <Header
                child={
                    <div style={{ padding: "0.5rem", display: "flex", justifyContent: "space-between" }}>
                        <h1>Templates</h1>
                        <button className="download-button" onClick={downloadTemplates}> Download </button>
                    </div>
                }
            />

            {/* Header Area Ended */}

            {
                showErrorMessage ?
                    <>
                        <h1 style={{ padding: "3%", textAlign: "center" }}>No Template Data Found.</h1>
                    </>
                    :

                    <div id="template" className="template">

                        {/* Modal Area for Template creation / edit */}

                        {
                            showModal ?
                                <div style={{ display: "flex", justifyContent: "center", alignItems: "center", flexDirection: "column", zIndex: 9999, position: "fixed", left: 0, top: 0, width: "100%", height: "100%", background: "rgba(255, 255, 255, 0.4)", backdropFilter: "blur(10px)" }}>
                                    <h1 onClick={() => setShowModal(!showModal)} style={{ cursor: "pointer", fontSize: "3rem", position: "absolute", right: "2%", top: "2%" }}>&times;</h1>

                                    <div style={{ border: "0.1rem solid grey", display: "flex", width: "90%", height: "75%" }}>

                                        {/* First Half for shoeing the body of temaplte and editing */}
                                        <div style={{ width: "50%", backgroundColor: "lightblue" }}>
                                            <textarea value={currTemplate.body} style={{ border: "none", borderRight: "0.1rem solid grey", width: "100%", height: "100%", resize: "none", outline: "none", padding: "3%" }}></textarea>
                                        </div>

                                        {/* second half box for tagging details and placeholder details... */}
                                        <div style={{ padding: "1%", width: "50%", backgroundColor: "white" }}>
                                            <div style={{ height: "50%" }}>
                                                <h1 style={{ fontSize: "1.6rem" }}>Tagging Details</h1><br /><hr /><br />
                                                <pre style={{ fontSize: "1.4rem", width: "100%", height: "100%", overflow: "auto" }}>
                                                    {JSON.stringify(currTemplate.tags, null, 2)}
                                                </pre>
                                            </div>

                                            <div style={{ height: "40%" }}>
                                                <h1 style={{ fontSize: "1.6rem" }}>Placeholders</h1><br /><hr /><br />
                                                <pre style={{ fontSize: "1.4rem", width: "100%", height: "100%", overflow: "auto" }}>
                                                    {JSON.stringify(currTemplate.placeholders, null, 2)}
                                                </pre>
                                            </div>
                                        </div>

                                    </div>

                                    <div style={{ border: "0.1rem solid grey", width: "90%", backgroundColor: "lightblue", padding: "1%", display: "flex", justifyContent: "flex-end" }}>
                                        <button onClick={() => setShowModal(!showModal)} style={{ margin: "0% 1%", padding: "0.5rem", fontSize: "1.5rem" }}>Cancel</button>
                                        <button style={{ margin: "0% 1%", padding: "0.5rem", fontSize: "1.5rem" }}>Save Changes</button>
                                    </div>
                                </div>
                                :
                                null
                        }

                        {/* Modal Area Ends Here... */}

                        <div onScroll={loadNextBatchOfTemplateData} className="template-container">

                            <div style={{ position: "sticky", top: 0, backgroundColor: "white", border: "0.1rem solid lightgrey", marginBottom: "1rem", display: "flex", padding: "1%", alignItems: "center" }}>
                                <p style={{ width: "5%", fontSize: "1.4rem" }}>Sr</p>
                                <p style={{ width: "30%", fontSize: "1.4rem" }}>Template ID</p>
                                <p style={{ width: "35%", fontSize: "1.4rem" }}>
                                    {/* searching area BTW */}
                                    <input type="text" className="template-name-search-input" placeholder="Template Name"
                                        value={searchTemplateByName}
                                        onFocus={() => setSearchTemplateByName("")}
                                        // onBlur={() => setSearchTemplateByName("Template Name")}
                                        onChange={(e) => {
                                            let text = e?.target?.value?.toLowerCase();

                                            let filter_temps = dupTemplates.filter((template) => template.name.toLowerCase().includes(text));

                                            setTemplates(filter_temps);

                                            setSearchTemplateByName(text);
                                        }}
                                    />
                                </p>
                                <p style={{ width: "10%", fontSize: "1.4rem" }}>Priority</p>
                                <p style={{ width: "10%", fontSize: "1.4rem" }}>Updated At</p>
                                <p style={{ width: "10%", fontSize: "1.4rem", textAlign: "center" }}>Action</p>
                            </div>

                            {
                                templates?.length > 0 ?

                                    templates?.map((template, index) => {
                                        return (
                                            <div style={{ marginBottom: "1rem", backgroundColor: "white", boxShadow: "0.1rem 0.1rem 0.5rem lightgrey", display: "flex", padding: "1%" }}>
                                                <p style={{ width: "5%", fontSize: "1.4rem" }}>{index + 1}</p>
                                                <p style={{ width: "30%", fontSize: "1.4rem", overflow: "auto" }}>{template._id}</p>
                                                <p onClick={() => {
                                                    setShowModal(true);
                                                    setCurrTemplate(template);
                                                }} style={{ cursor: "pointer", width: "35%", fontSize: "1.4rem", overflow: "auto" }}>{template.name}</p>
                                                <p style={{ width: "10%", fontSize: "1.4rem" }}>{template.priority ? template.priority : "-"}</p>
                                                <p style={{ width: "10%", fontSize: "1.4rem" }}>{new Date(template.updatedAt).toLocaleDateString()}</p>
                                                <p style={{ width: "5%", fontSize: "1.4rem", textAlign: "center", cursor: "pointer" }} onClick={() => {
                                                    setShowModal(true);
                                                    setCurrTemplate(template);

                                                }}>✒️</p>
                                                <p style={{ width: "5%", fontSize: "1.4rem", textAlign: "center" }}>🪣</p>
                                            </div>
                                        );
                                    })
                                    :
                                    null
                            }

                        </div>

                        {
                            templates.length === 0 ?
                                <h1 style={{ textAlign: "center" }}>No Template found with name: {searchTemplateByName}</h1>
                                :
                                null
                        }

                        {/* <button onClick={() => {
                            getTemplates(currPage + 1);
                            setCurrPage(currPage + 1);
                        }}>Load More</button> */}
                    </div>
            }
        </div>
    );
}

export default Templates;