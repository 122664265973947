import React, {useState} from "react";
import fetchData from "../data/fetchData";
import logoImg from "../assets/images/ori-logo.png";

import "../styles/login.css";

const LoginScreen = (props) => {

    const [isShowingPassword, setIsShowingPassword] = useState(false);

    const viewHidePassword = () => {
        const passwordElement = document.getElementById("password");

        if (isShowingPassword) passwordElement.type = "password";
        else passwordElement.type = "text";

        setIsShowingPassword(!isShowingPassword);
    }

    const login = async (e) => {
        try {

            e.preventDefault();

            const userId = document.getElementById("userId").value;
            const password = document.getElementById("password").value;

            // console.log({ userId, password });

            const res = await fetchData("POST", `${process.env.REACT_APP_SERVER_URL}/login`, { userId, password });

            if (res && res.name && res.token) {
                const userData = { ...res, status: true };
                localStorage.setItem("loginDetails", JSON.stringify(userData));
                props.callback(userData);
            } else {
                alert("Something went wrong...");
                // console.log(res);
            }

        } catch (err) {
            // console.log(err);
        }
    }

    return (
        <>
            <div className="main-container">
                <div className="LoginScreenImage">
                    <img alt="login-page-logo" src={logoImg} style={{ width: "100%", height: "auto" }} />
                </div>
                <form className="login-form" onSubmit={login}>

                    <div>
                        <h1 className="gradient-header">Email BOT Dashboard</h1>
                    </div>

                    <div></div>
                    <div></div>

                    <div className="form-cont">
                        <h2>👻</h2>
                        <input type="email" required id="userId" placeholder="Email ID" />
                    </div>

                    <div className="form-cont">
                        <h2>🔐</h2>
                        <input type="password" required id="password" placeholder="Password" />
                        

                        
                        <h2 onClick={viewHidePassword} style={{ userSelect: "none", position: "relative", border: "0.1rem solid grey", borderRadius: "5rem", cursor: "pointer" }} >
                            👁️
                            {
                                isShowingPassword
                                    ?
                                    <span style={{ position: "absolute", left: "45%", fontSize: "1.7rem" }}>/</span>
                                    :
                                    null
                            }
                        </h2>

                    </div>

                    <div></div>
                    <div></div>

                    <div style={{ display: "flex", justifyContent: "center" }}>
                        <input className="submit-button" type="submit" value="Login" />
                    </div>

                </form>
            </div>
        </>
    );
}

export default LoginScreen;