import React, { useState, useEffect } from "react";

const SearchComponent = (props) => {

    const [searchText, setSearchText] = useState("");
    const [searchParams, setSearchParams] = useState([]);
    const [searchType, setSearchType] = useState("intent");

    // console.log("props.utterances.length - ", props.utterances.length);

    const filterData = () => {

        if (!searchText || searchText === "") {
            return null;
        }

        if (searchParams && searchParams.length > 0 && searchParams.includes(`${searchType} : ${searchText}`)) {
            alert("Search param already added.");
            return null;
        }

        let newSearchParams = [];

        if (searchText !== "") {
            newSearchParams = [...searchParams, `${searchType} : ${searchText}`];
        } else {
            newSearchParams = [...searchParams];
        }

        setSearchText("");
        setSearchParams(newSearchParams);

        let intents = [], utterances = [], filteredData = [], fi = [], fu = [];
        // extract data
        for (const d of newSearchParams) {

            switch (d.split(" : ")[0]) {
                case "intent":
                    intents.push(d.split(" : ")[1]);
                    break;

                case "utterance":
                    utterances.push(d.split(" : ")[1]);
                    break;

                default:
            }
        }

        if (props.type === "high_intent") {
            // For intent specific
            if (intents.length > 0) {
                // eslint-disable-next-line
                fi = props.utterances.filter((h) => {
                    let i = 0;

                    for (i = 0; i < intents.length; ++i) {
                        if (!h.high_intents.includes(intents[i].trim())) {
                            break;
                        }
                    }

                    if (i === intents.length) {
                        return true;
                    }
                });
            }

            filteredData = [...fi, ...fu];
        }

        props.callback(searchText, filteredData);

    }

    const removeSearchParam = (val) => {
        setSearchParams(searchParams.filter((sp) => sp !== val));
    }

    useEffect(() => {
        filterData(); // eslint-disable-next-line
    }, [searchParams]);

    return (
        <>

            <div style={{ width: "100%", marginBottom: "0.5rem", display: "flex", justifyContent: "space-between", alignItems: "center"  }}>

                {/* <h1>Search Within</h1> */}

                {/* <div style={{ width: "50%", display: "flex", alignItems: "center" }}>
                    <label id="search_param" style={{ width: "45%", display: "flex", alignItems: "center" }}>
                        <input type="checkbox" name="search_param" style={{ width: "30%" }} />
                        <p>Intent</p>
                    </label>

                    <label id="search_param" style={{ width: "45%", display: "flex", alignItems: "center" }}>
                        <input type="checkbox" name="search_param" style={{ width: "30%" }} />
                        <p>Utterance</p>
                    </label>
                </div> */}

            </div>

            <div style={{ display: "flex", justifyContent: "space-between" }}>
                <select style={{ width: "20%" }} value={searchType} onChange={(e) => {
                    setSearchType(e.target.value);
                }}>
                    <option value="intent">Intent</option>
                    <option value="utterance">Utterance</option>
                </select>

                <input
                    style={{ width: "60%" }}
                    placeholder="Enter search text"
                    type="text"
                    value={searchText}
                    onChange={(e) => { setSearchText(e.target.value); }}
                />
                <button style={{ width: "10%" }} onClick={filterData}>+</button>
            </div>

            <br />

            <div style={{ display: "flex", flexWrap: "wrap" }}>
                {
                    searchParams.map((s) => {
                        return (
                            <div style={{ borderRadius: "0.5rem", display: "flex", alignItems: "center", marginRight: "1rem", padding: "0.5rem", backgroundColor: "lightgray", border: "0.1rem dotted grey" }}>
                                <p>{s}</p>
                                <h3 onClick={() => removeSearchParam(s)} style={{ cursor: "pointer", fontWeight: "bold", color: "red", width: "2rem", textAlign: "center" }}>&times;</h3>
                            </div>
                        );
                    })
                }
            </div>

        </>
    );
}

export default SearchComponent;