import React, { useEffect, useState } from "react";
import "../styles/processMail.css";

// import socketIOClient from "socket.io-client";
import Header from "../components/Header";

// let socket;

const ProcessMail = ({ socket, profile }) => {

  // const [isAlreadyFileToProcess, setIsAlreadyFileToProcess] = useState(false);

  const [isServerAvailableForProcessing, setIsServerAvailableForProcessing] = useState("");

  const [isFileValid, setIsFileValid] = useState(false);

  const [mailProcessingServerStatus, setMailProcessingServerStatus] = useState(false);

  const upload_data = async (e) => {
    try {

      e.preventDefault();

      const file = document.getElementById("processing_file");
      const channelName = document.getElementById("channel").value;

      const formData = new FormData();

      const fileName = file.files[0].name;
      const ext = fileName.split(".").slice(-1).join("").toLowerCase();

      if (ext !== "xlsx") {
        // console.log("Only .xlsx files will be processed.")
        alert("upload only xlsx file to process the mail.");
        return null;
      }

      formData.append("channel", channelName);
      formData.append("file1", file.files[0]);

      const raw_res = await fetch(`${process.env.REACT_APP_MICROSERVER_URL}/processMail`, {
        method: "POST",
        body: formData
      })

      const res = await raw_res.json();

      if (res && res.message === "OK") {
        // console.log("file uploaded. Firing an event for confirmation to server.");
        setIsFileValid(true);
        socket.emit("file_upload_done", res);
      }

    } catch (err) {
      // console.log(err);
    }
  }

  const getMailProcessingServerStatus = async () => {
    try {
      const raw_res = await fetch(`${process.env.REACT_APP_MICROSERVER_URL}`);
      const res = await raw_res.text();

      if (res.includes("ecs utils")) {
        setMailProcessingServerStatus(true)
      }

    } catch (err) {
      setMailProcessingServerStatus(false);
    }
  }

  // console.log("profile = ", profile);

  useEffect(() => {

    if (socket) {

      socket.on("welcome", (data) => {
        console.log(data);
      })

      socket.on("processing", (data) => {
        // console.log(data);

        let div = document.getElementById("live-preview-playground");

        let div_nest = document.createElement("div");
        div_nest.style.padding = "1rem";
        div_nest.style.borderBottom = "0.2rem dotted grey";

        let preElem = document.createElement("pre");
        preElem.textContent = JSON.stringify(data, null, 4);
        preElem.style.marginBottom = "1rem";
        preElem.style.fontSize = "1.3rem";

        let a = document.createElement("a");
        a.setAttribute("href", `https://vil-email-dev.oriserve.com/admin/diagnoseEmail?trackId=${data.data.data.trackingId}`);
        a.textContent = "Diagnose This Mail >>";
        a.setAttribute("target", "_blank");
        a.setAttribute("rel", "noreferrer");
        a.style.color = "lightblue";
        a.style.textDecoration = "none";

        div_nest.append(preElem);
        div_nest.append(a);

        div.prepend(div_nest)

      })

      socket.emit("checkAvailability", () => {

      })

      socket.on("checkAvailabilityResult", (data) => {
        setIsServerAvailableForProcessing(data);
      })

      getMailProcessingServerStatus(); // eslint-disable-next-line
    }

  }, [])


  return (
    <>
      <Header
        child={
          <div style={{ position: "sticky", top: 0, zIndex: 999, display: "flex", alignItems: "center", paddingLeft: "2%", justifyContent: "space-between" }}>
            <h1>Process Mail</h1>
            {
              mailProcessingServerStatus ?
                <p style={{ paddingRight: "1.5rem", color: "green" }}>Server is up for processing...</p>
                :
                <p style={{ paddingRight: "1.5rem", color: "red" }}>Server is down</p>
            }
          </div>
        }
      />

      <div className="MainContainer">

        {/* Form */}

        {
          !isServerAvailableForProcessing ?
            <>
              {
                isFileValid ?
                  <>
                    <button onClick={() => {
                      socket.emit("startProcessing");
                      setIsServerAvailableForProcessing(true);
                    }}>Start Processing</button>
                  </>
                  :
                  <>
                    <div className="form-div">

                      <form className="form" onSubmit={upload_data} encType="multipart/form-data">

                        <input type="file" id="processing_file" required accept=".xlsx" />
                        <input type="text" id="channel" required placeholder="Channel Name Ex: ORI-TEAM-123" />
                        <button id="submit"> Process </button>

                      </form>
                      {/* <br />
                      <br />

                      <div>
                        <h3>Note: </h3><br />
                        <p> &bull; Upload only xlsx file in order to process the mail. </p>
                        <p> &bull; Channel Name must be uniue in order to get the result clearly. </p>
                      </div> */}

                    </div>
                  </>
              }
            </>
            :
            <>
              <p>{isServerAvailableForProcessing}</p>
              <button onClick={() => {
                socket.emit("abortProcessing", "");
                setIsServerAvailableForProcessing(false);
                setIsFileValid(false);
              }}>Abort</button>
            </>
        }


        {/* Processing result. */}
        <div className="live-preview-playground" id="live-preview-playground">
          <p>Waiting for logs...</p>
        </div>

      </div >

    </>
  );
}

export default ProcessMail;