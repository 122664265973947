import React from "react";
import UtteranceCard from "./UtteranceCard";

const ReviewUtteranceBeforeSave = (props) => {
    return (
        <>
            <div style={{ overflow: "auto", zIndex: 9999, display: "grid", placeItems: "center", position: "fixed", top: 0, left: 0, width: "100%", height: "100%", background: "rgba(0, 0, 0, 0.5" }}>

                <h1 onClick={props.cancel} style={{ position: "absolute", cursor: "pointer", top: "1%", right: "0.5%", width: "5rem", height: "5rem", color: "white", fontSize: "3rem" }}>&times;</h1>

                <div style={{ width: "90%", height: "90%", backgroundColor: "white", display: "flex", flexDirection: "column", overflow: "auto" }}>

                    <div style={{ borderBottom: "0.1rem solid lightgrey", color: "red", display: "flex", width: "100%", padding: "1%" }}>
                        <h1>Review Changes</h1>
                    </div>


                    <div style={{ padding: "2%", overflow: "auto", flex: 1 }}>
                        {
                            props?.dataToBeReviewed?.map((uttr) => {
                                return (
                                    <div style={{ display: "flex" }}>
                                        <div style={{ width: "20%" }}>
                                            {
                                                uttr.isNew ?
                                                    <h1>New Utterance</h1>
                                                    :
                                                    <h1>Old Modified Utterance</h1>
                                            }
                                        </div>
                                        <div style={{ width: "80%" }}>
                                            <UtteranceCard
                                                _id={uttr._id}
                                                utterance={uttr.utterance}
                                                isSelectedToBeHighlighted={false}
                                                intents={uttr.high_intents}
                                                callback={(data) => {
                                                    // console.log(data);
                                                }}
                                            />
                                        </div>
                                    </div>
                                );
                            })
                        }
                    </div>

                    <div style={{ borderTop: "0.1rem solid lightgrey", display: "flex", justifyContent: "flex-end", width: "100%", padding: "1%" }}>
                        <button onClick={() => props.confirmation(false)} style={{ marginRight: "1rem", fontWeight: "bold", fontSize: "1.5rem", background: "none", border: "0.3rem solid green", padding: "0.5rem 2rem", borderRadius: "5rem", cursor: "pointer" }}>Cancel</button>
                        <button onClick={() => props.confirmation(true)} style={{ fontWeight: "bold", fontSize: "1.5rem", color: "white", background: "green", border: "0.3rem solid green", padding: "0.5rem 2rem", borderRadius: "5rem", cursor: "pointer" }}>Save</button>
                    </div>

                </div>

            </div>
        </>
    );
};

export default ReviewUtteranceBeforeSave;