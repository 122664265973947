import React, { useEffect, useState } from "react";
import { Routes, Route, Link, useLocation } from "react-router-dom";

import Feedback from "./components/feedback";

import socketIOClient from "socket.io-client";

import "./styles/index.css";
import "./styles/navbar.css";

// BotManager
import Welcome from "./BotManager/Welcome";
import Workflows from "./BotManager/Workflows";
import Workflow from "./BotManager/Workflow/Workflow";
import Intents from "./BotManager/Intents";
import TestingNumber from "./BotManager/TestingNumber";

import APILogs from "./BotManager/APILogs";
import ProcessMail from "./ProcessMail/ProcessMail";
import Templates from "./BotManager/Templates";
import BotReport from "./BotManager/BotReport";
import EmailHistory from "./BotManager/EmailHistory";

import { icons } from "./config/constants";

import OriLogo from "./assets/images/ori-logo-solo.png";
import OriLogoFull from "./assets/images/ori-logo.png";
import TestingEbot from "./Testing/TestingEbot";
import LoginScreen from "./Auth/LoginScreen";

import EmailBotDocs from "./BotManager/EmailBotDocs";

// AI
import AI from "./BotManager/AI";

// bot results live -> google sheet
// import EmailbotLiveBotLogs from "./BotManager/EmailBotLiveBotLogs";

const checkIsLoggedIn = () => {
    try {
        const ls = localStorage.getItem("loginDetails");

        if (ls) {
            const loginData = JSON.parse(ls);
            if (loginData.status === true) {
                return loginData;
            }
        } else {
            return false;
        }
    } catch (err) {
        return false;
    }
}

const App = () => {


    // feedback
    const [showFeedbackModal, setShowFeedbackModal] = useState(false);

    // showing profile
    const [showProfileInNavbarIfMobileDevice, setShowProfileInNavbarIfMobileDevice] = useState(false);

    const location = useLocation();

    const [Links, setLinks] = useState([]);

    const [isLoggedIn, setIsLoggedIn] = useState(false);
    const [toggleMenuIcon, setToggleMenuIcon] = useState(false);
    const [showProfileModal, setShowProfileModal] = useState(false);
    const [profile, setProfile] = useState({});
    // const [colatSidebar, setColatSidebar] = useState(true);

    const [socket, setSocket] = useState(null);

    const handleSideNav = () => {
        let sideNavbar = document.getElementById("side-nav-bar");
        sideNavbar.classList.toggle("wow");
        setToggleMenuIcon(!toggleMenuIcon);
    }

    const logout = () => {
        setIsLoggedIn(false);
        localStorage.setItem("loginDetails", "");
    }

    window.addEventListener("resize", () => {
        console.log(window.innerWidth);
        if (window.innerWidth < 800) {
            setShowProfileInNavbarIfMobileDevice(true);
        } else {
            setShowProfileInNavbarIfMobileDevice(false);
        }
    })

    useEffect(() => {

        const stat = checkIsLoggedIn();

        if (stat) {

            const socket1 = socketIOClient(process.env.REACT_APP_SERVER_URL, {
                path: "/utility/socket.io/",
                auth: {
                    token: stat?.token ?? ""
                }
            });

            setSocket(socket1);

            setIsLoggedIn(true);
            setProfile(stat);

            // Navigation links that is allowed to specific user
            setLinks(stat?.permissions?.botManager?.map((link) => ({ ...link, img: icons[link?.name ?? ""] })));

        } else {
            setIsLoggedIn(false);
        }

    }, [isLoggedIn]);

    return (
        <>

            {
                showFeedbackModal
                    ?
                    <Feedback
                        open={(res) => {
                            setShowProfileModal(false);
                            setShowFeedbackModal(res)
                        }}
                    />
                    :
                    null
            }

            {
                isLoggedIn ?
                    <>
                        {/* Mobile Nav Bar Header */}
                        <div className="mobile-nav-bar-header">

                            {/* ORI Icon */}

                            <div className="icon">
                                <Link to="/" style={{ textDecoration: "none" }}>
                                    <img alt="mobile-logo" src={OriLogoFull} style={{ width: "100%", padding: "5% 12%", backgroundColor: "white", position: "sticky", top: 0, zIndex: 999, }} />
                                </Link>
                            </div>

                            {/* Profile View Area */}
                            {/* 
                            <div onClick={() => setShowProfileModal(!showProfileModal)} style={{ cursor: "pointer", userSelect: "none", position: "relative" }}>
                                <h2 style={{ cursor: "pointer", display: "grid", placeItems: "center", width: "3rem", height: "3rem", borderRadius: "5rem", background: "linear-gradient(to bottom right, #50C878, #98FB98, lightgreen)", border: "0.2rem solid green" }}>P</h2>

                                {
                                    showProfileModal ?
                                        <>
                                            <div style={{ zIndex: 9999999999, position: "absolute", top: "100%", left: "-500%", display: "flex", flexDirection: "column", backgroundColor: "white", width: "30rem", height: "30rem", boxShadow: "0.1em 0.1rem 1rem grey", borderRadius: "0.5rem", padding: "2rem", right: 0, justifyContent: "space-between" }}>
                                                <div>
                                                    <p onClick={() => setShowProfileModal(!showProfileModal)} style={{ cursor: "pointer", fontSize: "3rem", textAlign: "right" }}>&times;</p>
                                                    <p style={{ fontSize: "1.6rem" }}>Welcome, <h2>{profile ? profile.name : ""}</h2></p>
                                                    <p><h2>{profile ? profile.email : ""}</h2></p>
                                                    <br />
                                                    <br />
                                                    <h3 style={{ padding: "0.5rem", cursor: "pointer", width: "50%", borderRadius: "5rem", textAlign: "center", backgroundColor: "lightgreen" }} onClick={logout}> Log Out  🧨</h3>
                                                    </div>

                                                <div>
                                                    <p style={{ cursor: "pointer", textAlign: "center" }} onClick={() => {
                                                        setShowProfileModal(false);
                                                        setShowFeedbackModal(true);
                                                    }} >Feedback</p>
                                                </div>
                                            </div>
                                        </>
                                        :
                                        null
                                }
                            </div> */}

                            {/*Hamburger Menu */}

                            <div onClick={handleSideNav} className="menu" style={{ cursor: "pointer", userSelect: "none" }}> {!toggleMenuIcon ? <p>&#9776;</p> : <p>&times;</p>}</div>
                        </div>

                        <div className="MainBox">
                            {/* Side Navigators */}
                            <div id="side-nav-bar" className="side-nav-bar">
                                <div>

                                    {
                                        showProfileInNavbarIfMobileDevice
                                            ?
                                            <div style={{ zIndex: 9999999999, display: "flex", flexDirection: "column", borderRadius: "0.5rem", padding: "2rem", right: 0, justifyContent: "space-between" }}>
                                                <div>
                                                    <p style={{ fontSize: "1.6rem" }}>Welcome, <h2>{profile ? profile.name : ""}</h2></p>
                                                    <p><h2>{profile ? profile.email : ""}</h2></p>
                                                    <br />
                                                    <br />
                                                    <h3 style={{ padding: "0.5rem", cursor: "pointer", width: "50%", borderRadius: "5rem", textAlign: "center", backgroundColor: "lightgreen" }} onClick={logout}> Log Out  🧨</h3>
                                                </div>
                                                <br />
                                                <br />
                                                <div>
                                                    <p style={{ cursor: "pointer", textAlign: "center" }} onClick={() => {
                                                        setShowProfileModal(false);
                                                        setShowFeedbackModal(true);
                                                    }} >Feedback</p>
                                                </div>
                                            </div>
                                            :
                                            <Link to="/" style={{ textDecoration: "none" }}>
                                                <img alt="nav-bar-logo" src={OriLogo} style={{ boxShadow: "0.1rem 0.1rem 0.5rem lightgrey", width: "100%", padding: "15% 25%", backgroundColor: "white", position: "sticky", top: 0, zIndex: 999, }} />
                                            </Link>

                                    }

                                    {/* <div style={{ zIndex: 9999999999, display: "flex", flexDirection: "column", borderRadius: "0.5rem", padding: "2rem", right: 0, justifyContent: "space-between" }}>
                                        <div>
                                            <p style={{ fontSize: "1.6rem" }}>Welcome, <h2>{profile ? profile.name : ""}</h2></p>
                                            <p><h2>{profile ? profile.email : ""}</h2></p>
                                            <br />
                                            <br />
                                            <h3 style={{ padding: "0.5rem", cursor: "pointer", width: "50%", borderRadius: "5rem", textAlign: "center", backgroundColor: "lightgreen" }} onClick={logout}> Log Out  🧨</h3>
                                             </div>
                                        <br />
                                        <br />
                                        <div>
                                            <p style={{ cursor: "pointer", textAlign: "center" }} onClick={() => {
                                                setShowProfileModal(false);
                                                setShowFeedbackModal(true);
                                            }} >Feedback</p>
                                        </div>
                                    </div> */}

                                    <br />

                                    {
                                        Links?.map((link) => {
                                            return (
                                                <div className="link_div" onClick={handleSideNav} style={{ background: location.pathname.endsWith(link.to) ? "linear-gradient(to bottom right, #50C878, #98FB98, lightgreen)" : "none" }} >
                                                    <Link className="link1" to={link.to}>
                                                        <img alt="logo" src={link.img} className="img" />
                                                        <span className="img-text">{link.name}</span>
                                                    </Link >
                                                    <Link className="link2" to={link.to}>{link.name}</Link>
                                                </div>
                                            );
                                        })
                                    }
                                </div>

                                {/* Show Big side bar */}
                                {/* <div style={{ display: "grid", placeItems: "center", paddingBottom: "1rem" }}>
                                    <h3 style={{ padding: "0.5rem", cursor: "pointer", width: "50%", borderRadius: "5rem", textAlign: "center", backgroundColor: "lightgreen" }}>&rsaquo;</h3>
                                </div> */}

                            </div>

                            {/* Main Viewer */}
                            <div className="main-window-viewer" >

                                {/* top navbar */}

                                <div style={{ backgroundColor: "#f6f6f6", width: "100%", height: "100%", overflow: "auto", overflowX: "hidden" }}>
                                    <Routes>
                                        <Route index element={<Welcome />} />

                                        <Route path="/BotManager" >
                                            <Route index element={<Welcome />} />
                                            <Route path="workflows" >
                                                <Route index element={<Workflows />} />
                                                <Route path=":id" element={<Workflow />} />
                                            </Route>
                                            <Route path="intents" element={<Intents />} />
                                            <Route path="templates" element={<Templates />} />
                                            <Route path="apilogs" element={<APILogs />} />
                                            <Route path="botreport" element={<BotReport />} />
                                            <Route path="emailhistory" element={<EmailHistory />} />
                                            <Route path="testingnumber" element={<TestingNumber />} />

                                        </Route>

                                        <Route path="processMail">
                                            <Route index element={<ProcessMail profile={profile} socket={socket} />} />
                                        </Route>

                                        <Route path="testmailcases">
                                            <Route index element={<TestingEbot />} />
                                        </Route>

                                        <Route path="docs">
                                            <Route index element={<EmailBotDocs />} />
                                        </Route>


                                        {/* <Route path="logs">
                                            <Route index element={<EmailbotLiveBotLogs />} />
                                        </Route> */}
                                        <Route path="ai">
                                            <Route index element={<AI />} />
                                        </Route>

                                    </Routes>
                                </div >
                            </div >

                        </div >

                    </>
                    :
                    <>
                        {/* Auth Screens */}
                        <LoginScreen
                            callback={(data) => setIsLoggedIn(data.status)}
                        />
                    </>
            }

        </>
    );
}

export { App as default, checkIsLoggedIn };