import React from "react";
import Header from "../components/Header";

const EmailBotDocs = () => {
    return (
        <div style={{ display: "flex", flexDirection: "column", width: "100%", height: "100%", minHeight: "20rem", flex: 1, justifyContent: "space-between" }}>
            <div>
                <Header
                    child={
                        <>
                            <h1>Email Bot Documentation</h1>
                        </>
                    }
                />
            </div>
            <div style={{ height: "90%" }}>
                <iframe title="email bot documentation" style={{ border: "none", width: "100%", height: "100%" }} src="https://docs.google.com/document/d/e/2PACX-1vSOvoUdAdUYExJhmZYzT2-srz5gFG2laNfNEkiZc0Z43PM0iHCbt_KZyZAhKitS8m-sBWQ2jVkHhgRP/pub?embedded=true"></iframe>
            </div>
        </div>
    );
}

export { EmailBotDocs as default };