import React, { useEffect, useState } from "react";
import fetchData from "../../data/fetchData";

const HighLevelIntentAnalytics = () => {

    const [max, setMax] = useState(0);
    const [data, setData] = useState([]);
    const [startDate, setStartDate] = useState(new Date().getFullYear() + "-" + (new Date().getMonth() + 1).toString().padStart(2, "0") + "-" + new Date().getDate().toString().padStart(2, "0") + "T00:00");
    const [endDate, setEndDate] = useState(new Date().getFullYear() + "-" + (new Date().getMonth() + 1).toString().padStart(2, "0") + "-" + new Date().getDate().toString().padStart(2, "0") + "T" + new Date().getHours().toString().padStart(2, "0") + ":" + new Date().getMinutes().toString().padStart(2, "0"));

    const getHighLevelIntentAnalyticsData = async () => {
        try {
            let res = await fetchData("GET", `${process.env.REACT_APP_SERVER_URL}/getHighLevelIntentData?startDate=${startDate}&endDate=${endDate}`)

            let sorted_res = res.sort((a, b) => b.count - a.count);

            setData(sorted_res);
            setMax(sorted_res[0].count);

        } catch (err) {
            // console.log(err);
        }
    }

    useEffect(() => {
        getHighLevelIntentAnalyticsData(); // eslint-disable-next-line
    }, [startDate, endDate]);

    return (
        <div style={{ padding: "1% 2%" }}>

            {/* Heading */}
            <div style={{ display: "flex", justifyContent: "space-between" }}>
                <h1 style={{ width: "60%" }}>High Level Intent Analytics</h1>
                <div style={{ width: "45%", display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                    <input style={{ width: "45%" }} type="datetime-local" value={startDate} onChange={(e) => setStartDate(e.target.value)} />
                    <h1>~</h1>
                    <input style={{ width: "45%" }} type="datetime-local" value={endDate} onChange={(e) => setEndDate(e.target.value)} />
                </div>
            </div>

            <br />
            <hr />
            <br />

            {/* Main Viewing area */}
            <div>
                {
                    data?.length > 0 ?
                        data?.map((intent) => {

                            let f = Math.floor, r = Math.random, maxAllowed = 255;
                            const bg = "rgba(" + f(r() * maxAllowed) + ", " + f(r() * maxAllowed) + ", " + f(r() * maxAllowed) + ", 1)";
                            const width = Math.ceil((intent.count / (max + 50)) * 100) + "%";

                            // console.log("Max - ", max);
                            // console.log("bg - ", bg);
                            // console.log("width - ", width);

                            return (
                                <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                                    <h2 style={{ width: "20%", padding: "1rem" }}>{intent.high_intent_name}</h2>
                                    <div style={{ width: "5%", borderLeft: "0.1rem solid lightgrey", padding: "1rem" }}>
                                        {intent.count}
                                    </div>
                                    <div style={{ width: "69%", display: "flex", padding: "1rem" }}>
                                        <div style={{ height: "1.6rem", backgroundColor: bg, width, boxShadow: "inset 0.1rem 0.1rem 0.5rem black" }}>&nbsp;</div>
                                    </div>
                                </div>
                            );
                        })
                        :
                        <>
                            <br />
                            <h1 style={{ textAlign: "center" }}> ⚠️ No Records Available.</h1>
                        </>
                }
            </div>
        </div>
    );
}

export { HighLevelIntentAnalytics as default };