import React, { useEffect, useState } from "react";
import Header from "../../components/Header";
import "./css/index.css";
import HighIntents from "./HighIntents";
import MidIntents from "./MidIntents";

const AI = () => {

    const [view, setView] = useState("");

    const rememberRoute = (val) => {
        if (localStorage) {
            localStorage.setItem("ai-training-route", val);
        }

        setView(val);
    }

    useEffect(() => {
        if (localStorage) {
            const routeRef = localStorage.getItem("ai-training-route");
            if (routeRef) setView(routeRef);
        }
    }, [])

    return (
        <div style={{ display: "flex", flexDirection: "column", flex: 1, minHeight: "20rem", width: "100%", height: "100%" }}>
            <Header
                child={
                    <div className="AI-header">
                        <div>
                            <h1>AI Training</h1>
                        </div>
                        <div>
                            <button onClick={() => rememberRoute("")}>🏠</button>
                            <button style={{ background: view === "1" ? "linear-gradient(to bottom right, #50C878, #98FB98, lightgreen)" : "white" }} onClick={() => rememberRoute("1")}>Mid intent</button>
                            <button style={{ background: view === "2" ? "linear-gradient(to bottom right, #50C878, #98FB98, lightgreen)" : "white" }} onClick={() => rememberRoute("2")}>High intent</button>
                        </div>

                        <div>
                            <button>Train</button>
                        </div>
                    </div>
                }
            />

            {
                view === "1" ?
                    <MidIntents />
                    :
                    view === "2" ?
                        <HighIntents />
                        :
                        <div>
                            <br /> <br />
                            <center><h1>Welcome to AI Training</h1></center>
                        </div>
            }
        </div>
    );
}

export { AI as default };