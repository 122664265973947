import React, { useState, useEffect } from "react";
import Header from "../components/Header";
import fetchData from "../data/fetchData";
import "../styles/email-history.css";
// import logospinner from "../assets/images/ori-logo-solo.png";

const EmailHistory = () => {

    const [history, setHistory] = useState([]);
    const [skip, setSkip] = useState(0);
    const [detailedData, setDetailedData] = useState({});
    const [totalDocs, setTotalDocs] = useState("0");
    const [showSpinner, setShowSpinner] = useState(false);
    const [showAIModal, setShowAIModal] = useState(false);
    const [showMoreDetailsOfUser, setShowMoreDetailsOfUser] = useState(false);

    const loadHistory = async () => {
        try {

            const data = await fetchData("POST", `${process.env.REACT_APP_SERVER_URL}/emailHistory?skip=${skip}`);

            // console.log(data);

            if (data && data.emailLogs) {
                setHistory([...history, ...data.emailLogs]);
                setTotalDocs(data.totalDocs);
            }

        } catch (err) {
            // console.log(err);
        }
    }

    const loadDataOnScroll = (e) => {
        const { offsetHeight, scrollTop, scrollHeight } = e.target;

        // console.log({ offsetHeight, scrollTop, scrollHeight });

        if (offsetHeight + scrollTop === scrollHeight) {
            setSkip(history.length);
        }
    }

    const loadDetailedData = async (trackId, status) => {
        try {

            if (status !== "completed") {
                alert("This email is not yet processed by bot.");
                return;
            }

            if (detailedData && detailedData.trackId === trackId) {
                return false;
            }

            setShowSpinner(true);
            // console.log("Datailed Data for trackId : ", trackId);

            const data = await fetchData("GET", `${process.env.REACT_APP_SERVER_URL}/getOneDetailedEmailHistory/${trackId}`);

            // console.log("Detailed Data  = ", data);

            if (data) {
                setDetailedData(data);
                setShowSpinner(false);
            } else {
                setShowSpinner(false);
            }

        } catch (err) {
            setShowSpinner(false);
            // console.log(err);
        }
    }

    useEffect(() => {
        loadHistory(); // eslint-disable-next-line
    }, [skip])

    return (
        <>
            {/* Spinner Modal */}
            {
                showSpinner ?
                    <div style={{ zIndex: 999999999999999, backgroundColor: "rgba(255, 255, 255, 0.7)", backdropFilter: "blur(10px)", position: "fixed", top: 0, left: 0, width: "100%", height: "100%", display: "grid", placeItems: "center" }}>
                        {/* <img src={logospinner} style={{ width: "10%" }} /> */}
                        <h1>Loading...</h1>
                        <progress></progress>
                    </div>
                    :
                    null
            }

            {/* AI Analysis Modal Viewer */}
            {
                showAIModal ?
                    <div style={{ zIndex: 999999999999, backgroundColor: "rgba(255, 255, 255, 0.7)", backdropFilter: "blur(10px)", position: "fixed", top: 0, left: 0, width: "100%", height: "100%", display: "flex", justifyContent: "space-around", padding: "2%" }}>

                        <h1 style={{ position: "absolute", right: "1rem", top: "1rem", cursor: "pointer" }} onClick={() => setShowAIModal(false)}>&times;</h1>

                        {/* Flow Permission */}
                        <div style={{ overflow: "auto", border: "0.1rem solid lightgrey", backgroundColor: "white", width: "45%" }}>
                            <h1 style={{ position: "sticky", top: 0, backgroundColor: "white", padding: "2%" }}>Flow Permissions</h1> <br />
                            <pre style={{ fontSize: "1.3rem", padding: "2%" }}>{JSON.stringify(detailedData?.flow_permissions, null, 4)}</pre>
                        </div>


                        {/* NlpSnapshot Section */}
                        <div style={{ overflow: "auto", border: "0.1rem solid lightgrey", backgroundColor: "white", width: "45%" }}>
                            <h1 style={{ position: "sticky", top: 0, backgroundColor: "white", padding: "2%" }}>NLP Snapshot</h1><br />
                            <pre style={{ fontSize: "1.3rem", padding: "2%" }}>{JSON.stringify(detailedData.nlpSnapShot, null, 4)}</pre>
                        </div>
                    </div>
                    :
                    null
            }

            {/* Show more details of user Modal */}
            {
                showMoreDetailsOfUser ?
                    <div style={{ zIndex: 999999999999, backgroundColor: "rgba(255, 255, 255, 0.7)", backdropFilter: "blur(10px)", position: "fixed", top: 0, left: 0, width: "100%", height: "100%", display: "flex", justifyContent: "space-around", padding: "2%" }}>

                        <h1 style={{ position: "absolute", right: "1rem", top: "1rem", cursor: "pointer" }} onClick={() => setShowMoreDetailsOfUser(false)}>&times;</h1>

                        {/* user profile*/}
                        <div style={{ overflow: "auto", border: "0.1rem solid lightgrey", backgroundColor: "white", width: "80%" }}>
                            <h1 style={{ position: "sticky", top: 0, backgroundColor: "white", padding: "2%" }}>User Details</h1><br />
                            <pre style={{ fontSize: "1.3rem", padding: "2%" }}>{JSON.stringify(detailedData.userProfile, null, 4)}</pre>
                        </div>
                    </div>
                    :
                    null
            }

            <div className="history-viewer">

                {/* Header */}
                <div className="history-viewer-header">

                    <Header
                        child={
                            <div>
                                <h1>Email History</h1>
                            </div>
                        }
                    />
                </div>


                {/* Main View */}
                <div className="history-viewer-container">

                    {/* Column 1 for history loading and all */}
                    <div onScroll={loadDataOnScroll}>

                        <div style={{ backgroundColor: "white", boxShadow: "0.1rem 0.1rem 0.5rem grey", position: "sticky", top: 0 }}>
                            <h3 style={{ padding: "1rem" }}>Date, filter and all adding soon...</h3>
                            <h3 style={{ padding: "1rem" }}>{history.length} / {totalDocs.toString()}  Results Loaded...</h3>
                        </div>

                        {
                            history?.map((email) => {
                                // console.log(detailedData?.from === email.sender);
                                return (
                                    <div onClick={() => loadDetailedData(email.trackId, email.processing_status)} className="history-comp" style={{ background: detailedData?.trackId && (detailedData?.trackId === email.trackId) ? "aliceblue" : "white" }}>
                                        <div style={{ justifyContent: "space-between", alignItems: "center", display: "flex" }}>
                                            <p>{email.processing_status}</p>
                                            <p>{email.failureReason?.length > 0 ? "🔴" : "🟢"}</p>
                                        </div>
                                        <p style={{ fontSize: "1.4rem" }}>{email.sender}</p>
                                        <p style={{ color: "grey" }}>{email.trackId}</p>
                                        <p>{new Date(email.createdAt).toLocaleDateString()} - {new Date(email.createdAt).toLocaleTimeString()}</p>
                                    </div>
                                );
                            })
                        }
                    </div>

                    {/* Column 2 for Showing outcome of the processed mail */}
                    {
                        detailedData?.trackId ?
                            <div>

                                <div style={{ height: "10%", display: "flex", alignItems: "flex-start", justifyContent: "space-between" }}>
                                    <p style={{ borderRadius: "0.5rem", fontSize: "1.2rem", color: "white", padding: "1rem", backgroundColor: detailedData?.controlledResponseMode?.includes("UNHANDLED") ? "red" : "green" }}>{detailedData.controlledResponseMode}</p>
                                    <p onClick={() => setShowAIModal(true)} style={{ borderRadius: "0.5rem", fontSize: "1.2rem", color: "white", padding: "1rem", backgroundColor: "blue", fontWeight: "bold" }}>AI + Flow Analysis</p>

                                    <a rel="noreferrer" style={{ color: "white", textDecoration: "none", borderRadius: "0.5rem", fontSize: "1.2rem", padding: "1rem", backgroundColor: "orange" }}
                                        href={`${process.env.REACT_APP_SERVER_URL}/admin/diagnoseEmail?trackId=${detailedData.trackId}`} target="_blank">
                                        Diagnose In Detail
                                    </a>
                                </div>

                                {/* Users mail query details -> The mail sent by user / received by bot */}
                                <div style={{ display: "flex", flexDirection: "column", height: "45%" }}>
                                    <p>Subject</p>
                                    <input className="query-viewer" type="text" value={detailedData?.subject ?? ""} /><br />
                                    <p>Body</p>
                                    <textarea className="query-viewer" style={{ width: "100%", flex: 1, resize: "none" }} value={detailedData?.content ?? ""}></textarea>
                                </div>

                                <br />
                                <hr />

                                {/* Mail output */}
                                <div style={{ height: "45%", display: "flex", flexDirection: "column", padding: "1rem 0" }}>
                                    <p>Template</p><br />
                                    <textarea className="query-viewer" style={{ width: "100%", flex: 1, resize: "none" }} value={detailedData?.template ?? ""}></textarea>
                                </div>
                            </div>
                            :
                            <div style={{ position: "absolute", left: "50%", top: "50%" }}>
                                <h1>🪔🌅☀️</h1>
                                <h1>Select one of the Email History to get the details...</h1>
                            </div>
                    }


                    {/* column 3 for showing what was happened while processing the mail - additional info and all */}
                    {
                        detailedData?.trackId ?
                            <div className="mail-and-user-info">
                                <div style={{ padding: "0.5rem", marginBottom: "1rem" }}>
                                    <div style={{ display: "flex", justifyContent: "space-between" }}>
                                        <h1>👨🏻‍🦱 &nbsp;User Details</h1>
                                        <h1 onClick={() => setShowMoreDetailsOfUser(!showMoreDetailsOfUser)} title="show more details of user" style={{ cursor: "pointer" }}>🔽</h1>
                                    </div>
                                    <br />
                                    <p>Number : {detailedData?.context_variables?.msisdn?.join(", ")}</p>
                                    <p>Name : {detailedData?.userProfile?.givenName ? detailedData?.userProfile?.givenName + " " + detailedData?.userProfile?.middleName : "NA"}</p>
                                    <p>Email : {detailedData?.userProfile?.contactMedium && detailedData?.userProfile?.contactMedium[0]?.emailAddress ? detailedData?.userProfile?.contactMedium[0]?.emailAddress : "NA"}</p>
                                    <p>Circle : {detailedData?.circleName} ({detailedData.circleId})</p>
                                    <p>Subscription Type : {detailedData?.subscriptionType}</p>
                                </div>

                                <details open>
                                    <summary title="Will help in identify that by which channel mail landed on bot">Channel Name</summary>
                                    <p>{detailedData?.channelName && detailedData?.channelName !== "NA" ? detailedData?.channelName : "NA"}</p>
                                </details>

                                <details open>
                                    <summary>Failure Reason {detailedData?.failureReason && detailedData?.failureReason !== "NA" ? JSON.parse(detailedData?.failureReason).length > 0 ? "🔴" : "" : ""} </summary>
                                    <p>{detailedData?.failureReason && detailedData?.failureReason !== "NA" ? JSON.parse(detailedData?.failureReason).join(", ") : "NA"}</p>
                                </details>

                                <details open>
                                    <summary>Workflow Events</summary>
                                    <p>{detailedData?.workFlowEvents && detailedData?.workFlowEvents !== "NA" ? JSON.parse(detailedData?.workFlowEvents).join(", ") : "NA"}</p>
                                </details>

                                <details open>
                                    <summary>Detected Entities</summary>
                                    <p>{detailedData?.context_variables?.entity_hash_map ? Object.keys(detailedData?.context_variables?.entity_hash_map).join(", ") : "NA"}</p>
                                </details>

                                <details open>
                                    <summary>Assited Reason</summary>
                                    <p>{detailedData?.assistedReasonList && detailedData?.assistedReasonList !== "NA" ? JSON.parse(detailedData?.assistedReasonList).map((s, i) => <p>{i + 1}. {s}</p>) : "NA"}</p>

                                    {/* {detailedData?.assistedReasonList ?? ""} */}
                                </details>

                                <details open>
                                    <summary>Unhandled Reason</summary>
                                    <p>{detailedData?.offBoundaryReasonList && detailedData?.offBoundaryReasonList !== "NA" ? JSON.parse(detailedData?.offBoundaryReasonList).join(", ") : "NA"}</p>
                                </details>

                                <details open>
                                    <summary>Tagging Details (Only for auto)</summary>
                                    <pre>{detailedData?.tagging && detailedData?.tagging !== "[]" ? JSON.stringify(JSON.parse(detailedData?.tagging), null, 2) : "NA"}</pre>
                                </details>
                            </div>
                            :
                            null
                    }

                </div>

            </div>

        </>
    );
}

export { EmailHistory as default };