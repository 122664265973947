import React, { useEffect, useState } from "react";
import fetchData from "../../data/fetchData";
import SearchComponent from "./comps/SearchComponent";
import "./css/index.css";

import ProcessUtteraceSheet from "./comps/ProcessUtteraceSheet";
import UtteranceCard from "./comps/UtteranceCard";
import ReviewUtteranceBeforeSave from "./comps/ReviewUtteranceBeforeSave";

const HighIntents = () => {

    const [currUttrDetails, setCurrUttrDetails] = useState({});

    // Total count of utterances
    const [totalHighUtterances, setTotalHighUtterances] = useState(0);

    // All utterances to listed and to be perform action over them
    const [highIntentUtterances, setHighIntentUtterances] = useState([]);
    const [dupHighIntentUtterances, setDupHighIntentUtterances] = useState([]);

    /**
     * - all unique high level intens in object of array form - sample
     * - with two replicas of it
     * [
     *      {
     *          "_id": "high_level_intent",
     *          "high_intents": "high_level_intent"
     *      }
     * ]
     */
    const [highIntents, setHighIntents] = useState([]);
    const [dupHighIntents, setDupHighIntents] = useState([]);
    const [dup2HighIntents, setDup2HighIntents] = useState([]);

    // Search text for high level intent seach for selection of high level intent
    const [highIntentSearchText, setHighIntentSearchText] = useState("");

    // Modal for selecting high level intens
    const [showIntentSelector, setShowIntentSelector] = useState(false);

    // List of selected high level intent for training
    const [selectedHighIntentsForTraining, setSelectedHighIntentsForTraining] = useState([]);

    // Utterance the long text upon which model will be trained
    const [utterance, setUtterance] = useState("");

    // infinite scroll pagination using skip
    const [skip, setSkip] = useState(0);

    // ReviewUtteranceBeforeSave
    const [showUttrReviewModal, setShowUttrReviewModal] = useState(false);

    // 
    const [dataToBeReviewed, setDataToBeReviewed] = useState([]);

    /**
     * Function will collect all the modified data and send to the server for saving in database
     * But before saving the data into database
     * it will open a modal to review the changes & confirmation and then once user confirm data will directly goto server and saved.
     */
    const getModifiedDataReviewedWithConfirmation = async () => {
        const modifiedData = highIntentUtterances.filter((uttr) => uttr.isModified);
        // console.log("modifiedData = ", modifiedData);

        if (modifiedData && modifiedData.length === 0) {
            alert("No modified utterance found to save.");
            return;
        }

        setDataToBeReviewed(modifiedData);
        setShowUttrReviewModal(true);
    }

    const getHighIntentUtterances = async () => {
        try {
            let data = await fetchData("GET", `${process.env.REACT_APP_SERVER_URL}/getHighIntentUtterances?skip=${skip}`);

            // console.log("getHighIntentUtterances = ", data);

            data.highIntens = data.highIntens.filter((d) => d._id ? true : false); // in case if there is no _id or at the place of intent name just don't include it in the list of unique high intents

            setHighIntents(data.highIntens);
            setDupHighIntents(data.highIntens);
            setDup2HighIntents(data.highIntens);

            setHighIntentUtterances([...highIntentUtterances, ...data.highlevelutterance]);
            setDupHighIntentUtterances([...dupHighIntentUtterances, ...data.highlevelutterance]);

            setTotalHighUtterances(data.totalUttrances);

        } catch (err) {
            console.log(err);
        }
    }

    /** 
     * Used to track what are the intents selected for the uttrance / to be trained with
     */
    const manageSelectedHighIntent = (val) => {

        let newData1 = highIntents.map((i) => {
            if (i?.high_intents === val && !i?.checked) return { ...i, checked: true };
            else if (i?.high_intents === val && i?.checked) return { ...i, checked: false };
            else return i;
        });

        let newData2 = dupHighIntents.map((i) => {
            if (i?.high_intents === val && !i?.checked) return { ...i, checked: true };
            else if (i?.high_intents === val && i?.checked) return { ...i, checked: false };
            else return i;
        });

        console.log(newData1, newData2);

        setHighIntents(newData1);
        setDupHighIntents(newData2);
    }

    const saveUtteranceForTraining = () => {
        console.log("Utterance => ", utterance);
        console.log("selectedHighIntentsForTraining => ", selectedHighIntentsForTraining[0]);

        // if no utterance entered. alert user to enter some text
        if (!utterance.trim()) {
            alert("Kindly add the utterance to be train with.");
            return null;
        }

        // if no intents selected. alert user to enter some text
        if (!selectedHighIntentsForTraining[0]?.trim()) {
            alert("Kindly select the intent for training");
            return null;
        }

        // If utterance is found in the first place inside list of utterance just update it with the latest selection of intents list
        for (const uttr1 in highIntentUtterances) {
            if (highIntentUtterances[uttr1] && highIntentUtterances[uttr1].utterance === utterance) {
                // alert("This utterance is already there in the high intent training dataset.");

                let data = [...highIntentUtterances];

                data[uttr1] = {
                    ...data[uttr1],
                    high_intents: selectedHighIntentsForTraining,
                    isModified: true
                }

                setUtterance("");
                setSelectedHighIntentsForTraining([]);

                setHighIntentUtterances(data);
                setDupHighIntentUtterances(data);

                return null;
            }
        }

        // Finally add new one if on utterance found with selected intents
        let uttToBeTrained = {
            _id: "new_uttr__" + new Date().getTime(),
            utterance,
            high_intents: selectedHighIntentsForTraining,
            isModified: true,
            isNew: true
        }

        setUtterance("");
        setSelectedHighIntentsForTraining([]);

        setHighIntentUtterances([uttToBeTrained, ...highIntentUtterances]);
        setDupHighIntentUtterances([uttToBeTrained, ...highIntentUtterances]);
        setTotalHighUtterances(totalHighUtterances + 1);
    }

    const deleteUtteranceForTraining = () => {
        setHighIntentUtterances(highIntentUtterances.filter((u) => {
            if (!u._id) return true;
            else if (u._id !== currUttrDetails._id) return true;
            else return false;
        }))

        setUtterance("");
        setSelectedHighIntentsForTraining([]);
        setCurrUttrDetails({});
        setTotalHighUtterances(totalHighUtterances - 1);
    }

    const loadUttrDetails = async (uttr) => {

        let new_high_intents = [...dup2HighIntents];

        // console.log("plane new_high_intents = ", new_high_intents);

        for (let i = 0; i < uttr.high_intents.length; ++i) {

            let index = new_high_intents.findIndex((intent) => intent.high_intents.includes(uttr.high_intents[i]));

            if (index >= 0) {
                new_high_intents[index] = { ...new_high_intents[index], checked: true };
            }
        }

        // console.log("after new_high_intents modify = ", new_high_intents);

        setHighIntents(new_high_intents);
        setDupHighIntents(new_high_intents);
        setSelectedHighIntentsForTraining(uttr.high_intents);
        setCurrUttrDetails(uttr);
        setUtterance(uttr.utterance);
    }

    // Scrolling controller
    const loadMoreUtterances = (e) => {

        const { offsetHeight, scrollHeight, scrollTop } = e.target;
        // console.log({ offsetHeight, scrollHeight, scrollTop });

        if (scrollHeight === scrollTop + offsetHeight - 2) {
            setSkip(highIntentUtterances.length);
        }
    }

    useEffect(() => {
        // console.log(setSkip);
        getHighIntentUtterances(); // eslint-disable-next-line
    }, [skip]);

    return (
        <>

            {
                showUttrReviewModal ?
                    <ReviewUtteranceBeforeSave
                        dataToBeReviewed={dataToBeReviewed}
                        cancel={() => setShowUttrReviewModal(false)}
                        confirmation={(res) => {
                            if (res) {
                                // We are finally ready to save the data
                                const res = window.confirm("Are you sure ?");
                                // console.log("Save data in db final confirmation response = ", res);

                                if (!res) {

                                    setShowUttrReviewModal(false);
                                    alert("Data Saving in DB cancelled.");
                                }

                                setShowUttrReviewModal(false);
                                // Call function that will save Data in DB

                            } else {
                                setShowUttrReviewModal(false);
                                alert("Data Saving in DB cancelled.");
                            }
                        }}
                    />
                    :
                    null
            }

            <div className="mid-level-container">

                {/* Mid level container child 1 just to show header */}
                {/* Green Top band
                contains heading 
                file upload thing
                save data button to save all utterance in DB
            */}

                <div>
                    {/* <h2>High Level Intent Training</h2> */}
                    <ProcessUtteraceSheet
                        training_type="high_intent"
                        callback={(dataOfSheet) => {
                            // console.log(dataOfSheet);
                            setHighIntentUtterances([...dataOfSheet, ...highIntentUtterances])
                            setDupHighIntentUtterances([...dataOfSheet, ...dupHighIntentUtterances])
                        }}
                    />
                    <div style={{ width: "25%", display: "flex", justifyContent: "flex-end" }}>
                        {
                            highIntentUtterances.filter((u) => u.isModified)?.length > 0 ?

                                <button onClick={getModifiedDataReviewedWithConfirmation} style={{ fontWeight: "bold", fontSize: "1.5rem", background: "none", border: "0.3rem solid green", padding: "0.5rem 2rem", borderRadius: "5rem", cursor: "pointer" }}>
                                    Save Data
                                </button>
                                :
                                null
                        }
                    </div>
                </div>

                {/* Main Playground Area */}


                {/* Mid level container child 2 having all the things */}
                <div style={{ display: "flex", flex: 1, justifyContent: "space-between" }}>

                    {/* Box 1 to show the utterances */}
                    <div onScroll={loadMoreUtterances} style={{ display: "flex", overflowY: "scroll", flexDirection: "column", width: "49%", backgroundColor: "white", borderRadius: "0.5rem", boxShadow: "0.1rem 0.1rem 0.5rem grey", border: "0.1rem solid grey" }}>

                        {/* Search BOX */}
                        <div style={{ width: "100%", borderBottom: "0.1rem solid lightgrey", position: "sticky", padding: "2rem", top: 0, backgroundColor: "white" }}>

                            <SearchComponent
                                utterances={dupHighIntentUtterances}
                                highIntents={dup2HighIntents}
                                type="high_intent"
                                callback={(inputText, filteredUttr) => {
                                    // console.log("inputText = ", inputText);
                                    setHighIntentUtterances(filteredUttr);
                                }}
                            />

                            <p style={{ margin: "0.5rem 0", textAlign: "right" }}>{`${highIntentUtterances.length} / ${totalHighUtterances}`} Results Loaded...</p>

                        </div>

                        {/* Search result data with all utterances */}
                        <div style={{ height: "10rem", padding: "1rem" }}>

                            {
                                highIntentUtterances?.map((uttr) => {
                                    return (
                                        <UtteranceCard
                                            _id={uttr._id}
                                            utterance={uttr.utterance}
                                            isSelectedToBeHighlighted={currUttrDetails._id === uttr._id ? true : false}
                                            intents={uttr.high_intents}
                                            isModified={uttr.isModified}
                                            callback={(data) => {
                                                // console.log(data);
                                                loadUttrDetails(uttr)
                                            }}
                                        />
                                    );
                                })
                            }

                        </div>
                    </div>

                    {/* Box 2 to show details with basic crud operations capability */}
                    <div style={{ position: "relative", display: "flex", flexDirection: "column", width: "49%", backgroundColor: "white", borderRadius: "0.5rem", boxShadow: "0.1rem 0.1rem 0.5rem grey", border: "0.1rem solid grey" }}>

                        {/* Intent Selector modal -> very handy to select multiple intents at a time */}
                        {
                            showIntentSelector ?
                                <div style={{ border: "0.1rem solid grey", overflow: "auto", borderRadius: "1rem 0 0 1rem", position: "absolute", width: "75%", height: "100%", backgroundColor: "white", right: 0 }}>

                                    <div style={{ padding: "1rem", backgroundColor: "white", textAlign: "right", fontSize: "2.5rem", borderBottom: "0.1rem solid lightgrey", cursor: "pointer", position: "sticky", top: 0 }}>
                                        <input
                                            placeholder="Enter text to search"
                                            type="text"
                                            value={highIntentSearchText}
                                            onChange={(e) => {
                                                setHighIntentSearchText(e.target.value);

                                                // console.log(e.target.value);

                                                if (e.target.value === "") {
                                                    setHighIntents(dupHighIntents);
                                                }
                                                else {
                                                    setHighIntents(dupHighIntents.filter((intent) => {
                                                        return intent?.mid_intents?.includes(e.target.value);
                                                    }))
                                                }

                                            }} />
                                    </div>

                                    <div>
                                        {
                                            highIntents?.map((intent) => {
                                                return (
                                                    <>
                                                        <label style={{ cursor: "pointer", borderBottom: "0.1rem solid lightgrey", display: "flex", width: "100%", padding: "1rem" }}>

                                                            <input onChange={(e) => {
                                                                // console.log(e.target.value);
                                                                manageSelectedHighIntent(intent.high_intents);
                                                            }}
                                                                checked={intent?.checked ?? false}
                                                                style={{ fontSize: "2rem", width: "20%" }}
                                                                type="checkbox"
                                                            />

                                                            <span>{intent?.high_intents ?? ""}</span>

                                                        </label>
                                                    </>
                                                );
                                            })
                                        }
                                    </div>

                                    <div style={{ display: "flex", justifyContent: "space-between", fontSize: "1.8rem", fontWeight: "bold", padding: "1.5rem", border: "0.1rem solid lightgrey", backgroundColor: "white", width: "100%", position: "sticky", bottom: 0 }}>

                                        <button
                                            onClick={() => {
                                                setSelectedHighIntentsForTraining([]);
                                                let newHighIntens = highIntents.map((i) => ({ ...i, checked: false }));
                                                setHighIntents(newHighIntens);
                                                setDupHighIntents(newHighIntens);
                                                setShowIntentSelector(false);
                                            }}
                                            style={{ cursor: "pointer", border: "0.1rem solid grey", fontSize: "1.8rem", width: "45%" }}>
                                            Cancel
                                        </button>

                                        <button
                                            onClick={() => {
                                                setSelectedHighIntentsForTraining(highIntents.map((i) => i.checked ? i.high_intents : "").filter((val) => val ? val : false))
                                                setShowIntentSelector(false);
                                            }}
                                            style={{ cursor: "pointer", border: "0.1rem solid grey", fontSize: "1.8rem", width: "45%" }}>
                                            Done
                                        </button>
                                    </div>
                                </div>
                                :
                                <></>
                        }

                        {/* Showing selected intents upon which utterance will be trained */}
                        <div style={{ height: "30%", padding: "1rem" }}>

                            <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                                <p style={{ fontSize: "1.5rem" }}>Intents To Be Trained with</p>
                                <h1 onClick={() => setShowIntentSelector(true)} style={{ cursor: "pointer", width: "3rem", height: "3rem", border: "0.2rem solid black", borderRadius: "5rem", display: "grid", placeItems: "center" }}>✚</h1>
                            </div>

                            <hr style={{ margin: "1rem 0" }} />
                            {
                                selectedHighIntentsForTraining.length > 0 ?
                                    // Intents To Be Trained with will be populate over here
                                    selectedHighIntentsForTraining?.map((i) => {
                                        return <p>{i}</p>
                                    })
                                    :
                                    <h1 onClick={() => setShowIntentSelector(true)} style={{ flex: 1, height: "70%", display: "grid", placeItems: "center", cursor: "pointer", color: "lightgrey" }}>Add +</h1>
                            }
                        </div>

                        {/* Basic Crud BUttons and textarea to add the mail utterance */}
                        <div style={{ flex: 1, padding: "1rem", height: "70%", display: "flex", flexDirection: "column", alignItems: "flex-end" }}>

                            <p style={{ fontSize: "1.6rem", marginBottom: "1rem", textAlign: "left", width: "100%" }}>Add Utterance</p>

                            <textarea
                                value={utterance}
                                onChange={(e) => setUtterance(e.target.value)}
                                placeholder="add utterance here" style={{ resize: "none", width: "100%", height: "70%", padding: "1rem" }}
                            >
                            </textarea><br />

                            <div style={{ width: "100%", display: "flex", justifyContent: "space-between" }}>
                                {
                                    currUttrDetails._id && currUttrDetails._id.includes("new_uttr_") ?
                                        <button onClick={deleteUtteranceForTraining} className="download-button">Delete</button>
                                        :
                                        <button></button>
                                }

                                <button onClick={saveUtteranceForTraining} className="download-button">Add</button>
                            </div>

                        </div>

                    </div>

                </div>
            </div>
        </>
    );
}

export { HighIntents as default };