import React, { useState, useEffect } from "react";
import Header from "../components/Header";
import fetchData from "../data/fetchData";
import "../styles/welcome.css";

import HighLevelIntentAnalytics from "./Analytics/HighLevelIntentAnalytics";
// import BotPerformanceTracker from "./Analytics/BotPerformanceTracker";
import UnhandledAnalytics from "./Analytics/UnhandledAnalytics";

import { Bar, BarChart, CartesianGrid, ResponsiveContainer, ComposedChart, Line, Tooltip, XAxis, YAxis } from "recharts";

const Welcome = () => {

  const [insights, setInsights] = useState({});
  const [currAnalyticsView, setCurrentAnalyticsView] = useState("default");

  let date = new Date();
  let d1 = date.getFullYear() + "-" + (date.getMonth() + 1).toString().padStart(2, "0") + "-01T00:00";
  let d2 = date.getFullYear() + "-" + (date.getMonth() + 1).toString().padStart(2, "0") + "-" + (date.getDate()).toString().padStart(2, "0") + "T00:00";

  const [startDate, setStartDate] = useState(d1);
  const [endDate, setEndDate] = useState(d2);

  const [currHandlingPercentage, setCurrHandlingPercentage] = useState(0);

  const [misData, setMisData] = useState([]);

  const changeAlayticsView = (type) => {
    setCurrentAnalyticsView(type);
  }

  useEffect(() => {

    const getInsights = async () => {
      try {

        let data = await fetchData("GET", `${process.env.REACT_APP_SERVER_URL}/getsomeinsights`);

        const all_mail = +data?.today["Processed Email Count"];
        const handled_count = +data?.today["Auto Count"] + +data?.today["Assited Count"];
        setCurrHandlingPercentage(((handled_count / all_mail) * 100).toFixed(2));

        console.log("insight data = ", data);

        if (data) {
          data.hourly_count_data_final = data.hourly_count_data_final.map((data) => ({
            hour: data._id + " - " + (parseInt(data._id) + 1),
            count: data.count
          }));
          setInsights(data);
          setMisData(data.misData);
        }

      } catch (err) {
        console.log("Error in get insights function...", err);
      }
    }

    getInsights()
  }, [])

  const getMisData = async () => {

    // console.log("startDate, endDate = ", startDate, endDate);

    let data = await fetchData("GET", `${process.env.REACT_APP_SERVER_URL}/getMisDetails?startDate=${startDate}&endDate=${endDate}`);

    // console.log("Data = ", data);

    setMisData(data);
  }

  useEffect(() => {
    getMisData(); // eslint-disable-next-line
  }, [startDate, endDate]);

  return (
    <>
      <Header child={
        <div style={{ padding: "0.5rem" }}>
          <center><h1 className="web-web">Welcome To Email Bot Dashboard</h1></center>
          <h2 className="web-mobile">Welcome To Email Bot Dashboard</h2>
        </div>
      }
      />

      <div className="analytics-band">

        {
          currAnalyticsView === "default" ?
            <p></p>
            :
            <div onClick={() => changeAlayticsView("default")}>
              <h3>Home</h3>
            </div>
        }
        <div onClick={() => changeAlayticsView("high-intent")}>
          <h3>High Level Intent Analytics</h3>
        </div>

        <div onClick={() => changeAlayticsView("unhandled-analytics")}>
          <h3>Unhandled Analytics</h3>
        </div>
      </div>

      {
        currAnalyticsView === "default" ?
          <>

            <h1 style={{ padding: "2%", color: "green" }}>Today's Insights</h1>

            <div className="insights">
              {
                Object.keys(insights?.today ?? {})?.map((key) => {
                  return (
                    <div className="insights-div">
                      <h2>{key}</h2>
                      <div style={{ borderTop: "0.1rem dashed lightgrey", margin: "1rem 0" }}></div>
                      <h1>{insights.today[key]}</h1>
                    </div>
                  );
                })
              }

              <div className="insights-div">
                <h2>Curr. Handling (%)</h2>
                <div style={{ borderTop: "0.1rem dashed lightgrey", margin: "1rem 0" }}></div>
                <h1>{currHandlingPercentage}</h1>
              </div>

            </div>

            {/* <BotPerformanceTracker misData={insights.misData} /> */}

            <h1 style={{ padding: "2%", color: "green" }}>VIL Email Bot Performance Tracker</h1>

            <div style={{ backgroundColor: "white", padding: "2rem 4% 1% 0%", display: "flex", flexDirection: "column", width: "100%", height: "35rem", justifyContent: "space-around" }}>

              <div style={{ display: "flex", alignItems: "center" }}>

                <div style={{ width: "50%", paddingLeft: "2%" }}>
                  <h2>Select Date Range</h2>
                </div>

                <div style={{ width: "50%", fontSize: "1rem", display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                  <input style={{ width: "48%", fontSize: "1.2rem" }} type="datetime-local" value={startDate} onChange={(e) => setStartDate(e.target.value)} />
                  <p>~</p>
                  <input style={{ width: "48%", fontSize: "1.2rem" }} type="datetime-local" value={endDate} onChange={(e) => setEndDate(e.target.value)} />
                </div>

              </div>

              <br />
              <br />

              <ResponsiveContainer width="100%" height="100%">
                <ComposedChart
                  width={300}
                  height={300}
                  margin={{ top: 0, left: 0, right: 0, bottom: 0 }}
                  data={misData}
                >
                  <CartesianGrid strokeDasharray="3 3" />
                  <XAxis dataKey="Date" />
                  <YAxis />
                  <Tooltip />
                  <Bar dataKey={"% handled"} fill="#cc99ff" />
                  {/* <Line type="monotone" dataKey="TotalCases" stroke="green" /> */}
                  {/* <Line type="monotone" dataKey="handled by bot" stroke="grey" /> */}
                  <Line type="monotone" dataKey="% handled" stroke="#5900b3" strokeWidth={10} />
                </ComposedChart>
              </ResponsiveContainer>
            </div>

            <br />
            <h1 style={{ padding: "2%", color: "green" }}>Hourly Email Count</h1>
            <div style={{ backgroundColor: "white", padding: "2% 2% 0 0", display: "flex", flexDirection: "column", width: "100%", height: "30rem", justifyContent: "space-around" }}>

              <ResponsiveContainer width="100%" height="100%">
                <BarChart
                  width={300}
                  height={300}
                  margin={0}
                  data={insights?.hourly_count_data_final}
                >
                  <CartesianGrid strokeDasharray="3 3" />
                  <XAxis dataKey="hour" />
                  <YAxis />
                  <Tooltip />
                  <Bar dataKey={"count"} fill="orange" />
                </BarChart>
              </ResponsiveContainer>
            </div>
          </>
          :
          currAnalyticsView === "unhandled-analytics" ?
            <UnhandledAnalytics />
            :
            <HighLevelIntentAnalytics />
      }

    </>
  );
}

export default Welcome;